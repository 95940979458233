import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthInterceptor } from './services/auth/auth.interceptor';

import { CoreModule } from './core/core.module';
import { CoreUiModule } from './core-ui/core-ui.module';

import { HighchartsChartModule } from 'highcharts-angular';

import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuardService } from './common/guards/auth-guard.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BroadcastService } from './services/broadcast.service';

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CoreModule,
        CoreUiModule,
        HighchartsChartModule,
        HttpClientModule,
        NgbModule,
        RouterModule,
        ToastModule,
        InfiniteScrollModule,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        AuthGuardService,
        BroadcastService,
        CurrencyPipe,
        MessageService,
        ConfirmationService,

        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
