import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService, HttpMethod } from '../api.service';
import { Location } from './location.model';
import { map } from 'rxjs/operators';
import { MetalPrices } from '../pricing/metal-prices.model';

@Injectable({
    providedIn: 'root'
})
export class LocationApiService extends ApiService
{
    constructor(protected http: HttpClient)
    {
        super(http);
    }

    getAllLocations(): Observable<Location []>
    {
        return this.apiRequest<any>(this.getAllLocationsUrl, HttpMethod.Get)
            .pipe(map(locations => locations.map(json => new Location(json))));
    }

    getMapLocations(): Observable<Location []>
    {
        return this.apiRequest<any>(this.getMapLocationsUrl, HttpMethod.Get)
            .pipe(map(locations => locations.map(json => new Location(json))));
    }

    getLocation(locationId: number): Observable<Location>
    {
        return this.apiRequest(this.getLocationUrl(locationId), HttpMethod.Get);
    }

    getCurrentLocation(): Observable<Location>
    {
        return this.apiRequest(this.getCurrentLocationUrl, HttpMethod.Get);
    }

    saveAnnouncement(headingHtml: string, textHtml: string, updateAllLocations: boolean): Observable<boolean>
    {
        const updateObj = Object.assign({}, { headingHtml, textHtml, updateAllLocations });
        return this.apiRequest<any>(this.saveAnnouncementUrl, HttpMethod.Post, JSON.stringify(updateObj)).pipe(map(() => true));
    }

    saveNews(headingHtml: string, textHtml: string, updateAllLocations: boolean): Observable<boolean>
    {
        const updateObj = Object.assign({}, { headingHtml, textHtml, updateAllLocations });
        return this.apiRequest<any>(this.saveNewsUrl, HttpMethod.Post, JSON.stringify(updateObj)).pipe(map(() => true));
    }

    // =============================================================================================================================
    // URLs
    // =============================================================================================================================
    /* tslint:disable:member-ordering */
    private get baseUrl(): string { return this.baseApiUrl + 'locations'; }
    private get getCurrentLocationUrl(): string { return `${this.baseUrl}/current`; }
    private getLocationUrl(id: number): string { return `${this.baseUrl}/${id}`; }
    private get getAllLocationsUrl(): string { return `${this.baseUrl}`; }
    private get getMapLocationsUrl(): string { return `${this.baseUrl}/mapLocations`; }
    private get saveAnnouncementUrl(): string { return `${this.baseUrl}/announcement`; }
    private get saveNewsUrl(): string { return `${this.baseUrl}/news`; }
}
