import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export enum LegendLogLevel
{
    Error = 0,
    Warning = 1,
    Info = 2
}

@Injectable()
export class LoggingService
{
    constructor() { }

    // Note: these are used in the environment.XYZ.ts files to set the log level

    private static LogLevelColors = [ 'color: red', 'color: yellow', 'color: blue' ];

    public static log(logLevel: LegendLogLevel, message: string, httpBody: any = null): void
    {
        if (logLevel > environment.logLevel) { return; }

        console.log(message, LoggingService.LogLevelColors[logLevel], httpBody);
    }
}
