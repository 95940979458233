import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AuthManagerService } from '../auth/auth-manager.service';
import { UserLogApiService } from './user-log-api.service';
import { UserLog } from './user-log.model';

@Injectable({
    providedIn: 'root'
})
export class UserLogManagerService
{
    constructor(private authService: AuthManagerService, private userLogApiService: UserLogApiService)
    {
    }

    getLogs(): Observable<UserLog []>
    {
        return this.userLogApiService.getLogs();
    }

    getLogsForVendor(vendorId: number): Observable<UserLog []>
    {
        return this.userLogApiService.getLogsForVendor(vendorId);
    }

    addUserLog(userLog: UserLog): Observable<UserLog>
    {
        return this.userLogApiService.addUserLog(userLog);
    }

    deleteUserLog(id: number): Observable<boolean>
    {
        return this.userLogApiService.deleteUserLog(id);
    }
}
