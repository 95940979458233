import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { CurrencyPipe } from '@angular/common';

import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';

import { CaptureSignatureComponent } from '../../capture-signature/capture-signature.component';
import { PurchaseOrderManagerService } from '../../../../services/purchase-order/purchase-order-manager.service';
import { PurchaseOrderPDFComponent } from '../../purchase-order-pdf/purchase-order-pdf.component';
import { ThemeManager } from '../../../../managers/theme.manager';

@Component({
    selector: 'ls-texas-compliance-page',
    templateUrl: './texas-compliance-page.component.html',
    styleUrls: [ './texas-compliance-page.component.less' ]
})
export class TexasCompliancePageComponent implements OnInit
{
    @ViewChild('signature', { static: false }) signature: CaptureSignatureComponent;

    @Output() signatureCompleted = new EventEmitter<boolean>();

    date: Date;
    name = '';

    headerText = 'Purchase Agreement';

    buyerText = 'Buyer:';

    declaration1 = 'I, the undersigned, hereby certify that I am the legal owner of all the merchandise sold. I have';
    declaration2 = 'an active license, certificate, registration, documentation showing my business entity status at';
    declaration3 = 'point of sale. I sell regulated material and/or regulated metal in the ordinary course of my';
    declaration4 = 'business and I acknowledge this receipt as payment in full.';

    sellerInfoHeaderText = 'Seller:';

    signatureText = 'Signature ______________________________________________________';
    printText = 'Print _________________________________________________________';
    dateText = 'Date ___________________________';

    columns = [
        { title: 'PO #', widthPercent: 50, align: 'left' },
        { title: 'Total Purchase', widthPercent: 50, align: 'left' },
    ];

    ThemeManager = ThemeManager;

    constructor(public poManager: PurchaseOrderManagerService, private currencyPipe: CurrencyPipe) { }

    ngOnInit(): void
    {
    }

    generateSignaturePage(parent: PurchaseOrderPDFComponent): void
    {
        // Add Title
        parent.outputComplianceHeader(this.headerText);

        // Use "table headers" to create the pseudo-table
        parent.createTableHeader(this.columns, true, false, parent.pdfDimensions.general.bodyWidth * 0.5);
        const columnOutput = cloneDeep(this.columns);
        columnOutput[0].title = parent.po.number;
        columnOutput[1].title = this.currencyPipe.transform(parent.po.totalConvertersPrice, 'USD');
        parent.createTableHeader(columnOutput, true, false, parent.pdfDimensions.general.bodyWidth * 0.5);
        parent.result.bottomOfOutput += parent.lineHeight * 2;

        parent.result.bottomOfOutput += parent.lineHeight * 3;
        parent.outputTextLine(this.buyerText);
        parent.result.bottomOfOutput += parent.lineHeight;
        parent.outputTextLine(parent.po.buyerName);
        parent.outputTextLine(parent.po.location.address);
        parent.outputTextLine(`${parent.po.location.city}, ${parent.po.location.state}  ${parent.po.location.zip}`);

        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(this.declaration1);
        parent.outputTextLine(this.declaration2);
        parent.outputTextLine(this.declaration3);
        parent.outputTextLine(this.declaration4);

        const vendor = parent.po.vendor;
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(this.sellerInfoHeaderText);
        parent.result.bottomOfOutput += parent.lineHeight;
        parent.outputTextLine(vendor.name);
        parent.outputTextLine(vendor.address);
        parent.outputTextLine(`${vendor.city}, ${vendor.state}  ${vendor.zip}`);

        // tslint:disable:max-line-length

        parent.result.bottomOfOutput += parent.lineHeight * 2;
        const imageHeight = parent.lineHeight * 2;
        const widthToHeightRatio = this.signature.widthToHeightRatio;
        parent.outputText(`${this.signatureText}  ${this.dateText}`);
        parent.outputImage(this.signature.signature, 'PNG', parent.leftMargin + parent.textWidth(this.removeUnderscores(this.signatureText)), imageHeight * widthToHeightRatio, imageHeight);
        parent.outputTextLine(moment(this.date).format('MM-DD-yyyy'), parent.textWidth(`${this.signatureText}  `) +
            parent.textWidth(this.removeUnderscores(this.dateText) + 5));

        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(this.printText);
        parent.outputText(this.name, parent.textWidth(this.removeUnderscores(this.printText) + 5));
        // tslint:enable:max-line-length

        this.clearSignatures();
    }

    removeUnderscores(text: string): string
    {
        return text.replace(/_/g, '');
    }

    clearSignatures(): void
    {
        this.signature.clear();
    }

    onCancel(): void
    {
        this.signatureCompleted.emit(false);
    }

    onSave(): void
    {
        this.signatureCompleted.emit(true);
    }
}
