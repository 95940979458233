import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Injectable({
    providedIn: 'root'
})
export class DeviceManagerService implements OnDestroy
{
    static MAX_MOBILE_WINDOW_WIDTH = 991;
    static MIN_DESKTOP_WINDOW_WIDTH = 1400;

    windowResize = new Subject<{ width: number, height: number }>();						// Fired when application window is resized.
    isMobileChange = new Subject<boolean>();			// Fired when isMobile state of application is changed.
    isTabletChange = new Subject<boolean>();			// Fired when isTable state of application is changed.
    isDesktopChange = new Subject<boolean>();			// Fired when isDesktop state of application is changed.

    isDesktop = true;
    isMobile = false;
    isTablet = false;

    constructor()
    {
        this.isMobile = window.innerWidth < DeviceManagerService.MAX_MOBILE_WINDOW_WIDTH;
        this.isTablet = window.innerWidth > DeviceManagerService.MAX_MOBILE_WINDOW_WIDTH &&
            window.innerWidth < DeviceManagerService.MIN_DESKTOP_WINDOW_WIDTH;
        this.isDesktop = window.innerWidth >= DeviceManagerService.MIN_DESKTOP_WINDOW_WIDTH;
        this.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() =>
            {
                this.dispatchResizeEvents(window.innerWidth);
            });
    }

    ngOnDestroy(): void
    {
    }

    get isiOS(): boolean { return /iPad|iPhone|iPod/.test(navigator.platform)
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); }

    private dispatchResizeEvents(windowInnerWidth: number): void
    {
        const newIsMobile = windowInnerWidth <= DeviceManagerService.MAX_MOBILE_WINDOW_WIDTH;
        if (this.isMobile !== newIsMobile)
        {
            this.isMobile = newIsMobile;
            this.isMobileChange.next(this.isMobile);
        }

        const newIsTablet = windowInnerWidth > DeviceManagerService.MAX_MOBILE_WINDOW_WIDTH &&
            windowInnerWidth < DeviceManagerService.MIN_DESKTOP_WINDOW_WIDTH;
        if (this.isTablet !== newIsTablet)
        {
            this.isTablet = newIsTablet;
            this.isTabletChange.next(this.isTablet);
        }

        const newIsDesktop = windowInnerWidth >= DeviceManagerService.MIN_DESKTOP_WINDOW_WIDTH;
        if (this.isDesktop !== newIsDesktop)
        {
            this.isDesktop = newIsDesktop;
            this.isDesktopChange.next(this.isDesktop);
        }
    }
}
