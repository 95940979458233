import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ls-form-input-field-error',
    templateUrl: './form-input-field-error.component.html',
    styleUrls: [ './form-input-field-error.component.less' ]
})
export class FormInputFieldErrorComponent implements OnInit
{
    private _text: string;
    @Input() set text(value: string)
    {
        this._text = value;
        this.updateMessages();
    }

    private _severity = 'error';
    @Input() set severity(value: string)
    {
        this._severity = value;
        this.updateMessages();
    }

    messages = [];
    constructor() { }

    ngOnInit(): void
    {
    }

    private updateMessages(): void
    {
        this.messages = this._text == null || this._text.length === 0 ? [] :
            [
                {
                    severity: this._severity,
                    detail: this._text,
                }
            ];
    }
}
