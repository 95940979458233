import * as moment from 'moment';

export class CachedValue<T>
{
    timestamp: Date;
    value: T;

    // Cache duration in SECONDS
    constructor(v: T, private cacheDuration: number = 300)
    {
        this.timestamp = new Date();
        this.value = v;
    }


    public get isExpired(): boolean
    {
        return moment(new Date()).diff(moment(this.timestamp), 'seconds') >= this.cacheDuration;
    }
}
