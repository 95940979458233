import { Injectable } from '@angular/core';
import { LegendLogLevel, LoggingService } from './logging.service';

@Injectable()
export class ErrorHandlerService
{
    constructor() { }

    static handleError(caller: object, error: any, callerPrefix?: string, showDetail: boolean = true,
                       bubbleError: boolean = true): any
    {
        error = error || { message: 'A network error has occurred.' };

        let message = '';
        if (error.error)
        {
            const errData = typeof(error.error) === 'string' ? JSON.parse(error.error) : error.error;
            // noinspection JSUnusedLocalSymbols
            for (const [k, v] of Object.entries(errData))
            {
                message += v + ', ';
            }
            message = message.substring(0, message.length - 2);
        }
        else
        {
            message = error.message || error.statusText || 'Unknown Error';
        }

        const msgHeader = `[${caller.constructor.name}${callerPrefix ? `::${callerPrefix}` : ''}]`;

        if (!bubbleError)
        {
            if (showDetail)
            {
                LoggingService.log(LegendLogLevel.Error, '%c' + `${msgHeader}: ` + message);
            }
            else
            {
                LoggingService.log(LegendLogLevel.Error, '%c' + message);
            }
            return message;
        }
        throw error;
    }
}
