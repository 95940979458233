import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PurchaseOrderManagerService } from '../../../services/purchase-order/purchase-order-manager.service';
import { AuthManagerService } from '../../../services/auth/auth-manager.service';
import { MenuItem } from 'primeng/api';
import { VendorManagerService } from '../../../services/vendor/vendor-manager.service';
import { Vendor } from '../../../services/vendor/vendor.model';
import { BroadcastService } from '../../../services/broadcast.service';
import { Menu } from 'primeng/menu';
import { DeviceManagerService } from '../../../core/services/device-manager.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ThemeManager } from '../../../managers/theme.manager';

@Component({
    selector: 'ls-purchase-order-toolbar',
    templateUrl: './purchase-order-toolbar.component.html',
    styleUrls: [ './purchase-order-toolbar.component.less' ]
})
export class PurchaseOrderToolbarComponent implements OnInit, OnDestroy
{
    @HostBinding('class') get classes(): string { return 'dark'; }

    @ViewChild('vendorMenu') vendorMenu: Menu;

    dropdownHeight = 500;
    vendors: Vendor[];
    selectedVendorId: number;

    constructor(public authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private deviceManager: DeviceManagerService,
                public poManager: PurchaseOrderManagerService,
                private vendorManager: VendorManagerService) { }

    ngOnInit(): void
    {
        this.dropdownHeight = window.innerHeight * 0.8;
        this.deviceManager.windowResize.pipe(untilDestroyed(this)).subscribe(size => this.dropdownHeight = size.height * 0.8);
        this.authManager.currentUserChanged.pipe(untilDestroyed(this)).subscribe(user => this.vendors = null);
    }

    ngOnDestroy(): void { }

    showPO(): void
    {
        this.broadcastService.displayPurchaseOrder.next(this.poManager.current);
    }

    createPO(): void
    {
        this.poManager.createPO(this.selectedVendorId).subscribe();
        this.selectedVendorId = null;
    }

    dropdownShown(): void
    {
        if (this.vendors != null)
        {
            if (this.vendors.length === 1)
            {
                this.selectedVendorId = this.vendors[0].id;
                this.createPO();
            }

            return;
        }

        this.vendors = [ new Vendor({ name: 'Loading...', id: 0}) ];
        this.vendorManager.getAllVendors(this.authManager.currentUser.locationId, true)
            .subscribe(vendors => {
                this.vendors = vendors.vendors;
                if (this.vendors.length === 1)
                {
                    this.selectedVendorId = this.vendors[0].id;
                    this.createPO();
                }
            });
    }
}
