import { Component, HostBinding, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import * as Highcharts from 'highcharts';

import { DeviceManagerService } from './core/services/device-manager.service';
import { BroadcastService } from './services/broadcast.service';
import { PurchaseOrder } from './services/purchase-order/purchase-order.model';
import { PurchaseOrderComponent } from './core-ui/purchase-order/purchase-order/purchase-order.component';
import { AuthManagerService } from './services/auth/auth-manager.service';
import { ThemeManager } from './managers/theme.manager';
import { NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';
import { LogListComponent } from './core-ui/user-logs/log-list/log-list.component';

@Component({
    selector: 'ls-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy
{
    private initialScriptHash: string;
    @HostBinding('class') get classes(): string { return ThemeManager.theme; }

    useNoPadding = false;
    title = 'LegendSiteV2';
    po: PurchaseOrder;
    vendorIdForLogs: number;

    constructor(private http: HttpClient,
                private deviceManager: DeviceManagerService,
                private authService: AuthManagerService,
                private broadcastService: BroadcastService,
                private renderer: Renderer2,
                private router: Router)
    {
        this.broadcastService.displayPurchaseOrder.subscribe(po =>
        {
            if (PurchaseOrderComponent.isDisplayed) return;
            this.po = po;
            this.vendorIdForLogs = null;
        });
        this.broadcastService.displayLogsForVendor.subscribe(id =>
        {
            if (LogListComponent.isDisplayed) return;
            this.vendorIdForLogs = id;
            this.po = null;
        });
        this.authService.userLoggedOut.subscribe(() =>
        {
            this.po = null;
            this.vendorIdForLogs = null;
        });
        ThemeManager.themeChanged.subscribe(() => {
            this.renderer.removeClass(document.body, 'dark');
            this.renderer.removeClass(document.body, 'light');
            this.renderer.addClass(document.body, ThemeManager.theme);
        });
    }

    ngOnInit(): void
    {
        Highcharts.setOptions({
            colors: [ '#a4bdda', '#345680', '#5900b3' ]
        });

        this.renderer.addClass(document.body, ThemeManager.theme);

        // const cssLinks = document.head.getElementsByTagName('link');
        // for (let i = cssLinks.length - 1; i >= 0; i--) {
        //     const scriptSource = cssLinks[i].getAttribute('href');
        //     if (scriptSource != null && scriptSource === 'dark-styles.css') {
        //         // cssLinks[i].disabled = true;
        //     }
        // }

        // Start check for version changes
        this.getVersionHash().subscribe(hash => {
            this.initialScriptHash = hash;
            this.monitorForVersionChanges();
        });

        this.router.events
            .pipe(filter(event => event instanceof NavigationStart), untilDestroyed(this))
            .subscribe((event: NavigationStart) => this.useNoPadding = event.url.indexOf('welcome') !== -1);
    }

    // For untilDestroyed
    ngOnDestroy(): void {
    }

    @HostListener('window:resize')
    onResize(): void {
        // Set up the host listener to listen for resize events to trigger hiding/showing
        // of hamburger menu and responsive design of navigation pane.
        this.deviceManager.windowResize.next({ width: window.innerWidth, height: window.innerHeight });
    }

    get theme(): string { return ThemeManager.theme; }

    private getVersionHash(): Observable<string>
    {
        const url = environment.versionCheckUrl;
        if (url == null) return of('');
        return this.http.get(url + '?t=' + new Date().getTime())
            .pipe(map((response: any) => response.hash));
    }

    private monitorForVersionChanges(): void
    {
        if (this.initialScriptHash == null) return;
        setInterval(() =>
        {
            this.getVersionHash().subscribe(newHash =>
            {
                if (newHash == null || newHash.localeCompare(this.initialScriptHash) === 0) return;
                console.log(`Main hash changed from ${this.initialScriptHash} to ${newHash} - forcing reload.`);
                this.initialScriptHash = newHash;
                alert('There is a new update to the Legend Database. The page will automatically reload to ensure you have the latest update.');
                window.location.reload();
            });
        }, 30000);
    }
}
