<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<form [hidden]="currentPage !== 1" class="po-pdf page-1">
    <div class="header">{{headerText}}</div>

    <div class="intro">
        <span class="intro-regular">{{introRegularText1}} {{introRegularText2}}</span>
    </div>

    <div class="parties" [innerHTML]="partiesTextHTML"></div>

    <div class="po-info">
        <table>
            <tr><th *ngFor="let column of columns" [style.width]="column.widthPercent + '%'" [style.text-align]="'center'">{{column.title}}</th></tr>

            <tr class="bordered-row">
                <td [style.width]="columns[0].widthPercent + '%'" [style.text-align]="'center'">{{poManager.current.number}}</td>
                <td [style.width]="columns[1].widthPercent + '%'" [style.text-align]="'center'">Converters</td>
                <td [style.width]="columns[2].widthPercent + '%'" [style.text-align]="'center'">{{poManager.current.totalNumConverters}}</td>
                <td [style.width]="columns[3].widthPercent + '%'" [style.text-align]="'center'">{{poManager.current.totalConvertersPrice | currency:'USD':'symbol'}}</td>
            </tr>
        </table>
    </div>

    <div class="payment-type">
        <div>{{paymentTypeHeaderText}}</div>
        <div class="payment-type-checkboxes">
            <p-checkbox *ngFor="let paymentType of paymentTypes" [(ngModel)]="selectedPaymentTypes" [value]="paymentType.value" [label]="paymentType.title" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
    </div>

    <div *ngIf="!isCashPayment" class="seller-info">
        <div>{{sellerInfoHeaderText}}</div>

        <div class="multiple-fields">
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(vehicleLicenseText)}}</span><input type="text" pInputText [(ngModel)]="vehicleLicenseNum" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(stateText)}}</span><input type="text" pInputText [(ngModel)]="vehicleLicenseState" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
        </div>

        <div class="multiple-fields">
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(idText)}}</span><input type="text" pInputText [(ngModel)]="idNum" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(stateText)}}</span><input type="text" pInputText [(ngModel)]="idState" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
        </div>
    </div>

    <div class="signature-section">

        <div>{{signaturePrelude}}</div>
        <div class="signature-prelude-warning">{{signaturePreludeWarning}}</div>

        <div class="multi-signature-line tall signature">
            <div class="text">{{removeUnderscores(signatureText)}}</div>
            <div class="signature-auto-expand"><ls-capture-signature #sellerSignature></ls-capture-signature></div>
        </div>

        <div class="multiple-fields name-and-date">
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(printText)}}</span><input type="text" pInputText [(ngModel)]="name" [ngModelOptions]="{standalone: true}" class="name"/>
                </div>
            </div>
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(dateText)}}</span>
                    <p-calendar [(ngModel)]="dateOfSale" [ngModelOptions]="{standalone: true}" [styleClass]="ThemeManager.theme" appendTo="body" inputStyleClass="calendar-input-style"></p-calendar>
                </div>
            </div>
        </div>
    </div>

    <div class="buttons">
        <div class="buttons-left">
            <button class="btn btn-secondary" (click)="clearSignatures()">Clear Signatures</button>
        </div>
        <div class="buttons-right">
            <button class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            <button class="btn btn-primary" [disabled]="sellerSignature.isEmpty || name.length === 0 || dateOfSale == null || selectedPaymentTypes.length === 0 ||
                (!isCashPayment && (vehicleLicenseNum.length === 0 || vehicleLicenseState.length === 0 || idNum.length === 0 || idState.length === 0 || selectedPaymentTypes.length === 0))"
                    (click)="onConfirmGeneralPage()">Confirm</button>
        </div>
    </div>
</form>

<div [hidden]="currentPage !== 2" class="po-pdf page-2">
    <div class="header">
        <div class="header-left">
            <div class="logo"><img src="/assets/images/ca_doj_logo.png" alt="logo"/></div>
            <div class="sub-text">
                <div>{{stateOfCAText}}</div>
                <div>{{bciaText}}</div>
                <div>{{origText}}</div>
            </div>
        </div>
        <div class="main-header">{{miscHeaderText}}</div>
        <div class="sub-text">
            <div>{{deptOfJusticeText}}</div>
            <div>{{headerPageNumberText}}</div>
        </div>
    </div>

    <div class="po-info">
        <div class="divider"></div>
        <div>{{miscLine1Text}}</div>
        <div class="divider"></div>
        <div><span class="section-label">{{removeUnderscores(dateOfSaleLabel)}}</span><span>{{dateOfSale | date:'MM/dd/yy'}}</span></div>
        <div class="divider"></div>

        <div class="section-label">{{placeOfSaleLabel}}</div>
        <p-dropdown [options]="placeOfSaleOptions" [(ngModel)]="placeOfSaleOption" optionLabel="label" optionValue="value" (onChange)="onPlaceOfSaleOptionChanged()"></p-dropdown>
        <div class="sale-address">
            <ng-container *ngIf="placeOfSaleOption === 1 || placeOfSaleOption === 2">
                <div class="street-address"><div>{{placeOfSaleStreetAddress}}</div><div>{{streetAddressLabel}}</div></div>
                <div class="city"><div>{{placeOfSaleCity}}</div><div>{{cityLabel}}</div></div>
                <div class="state"><div>{{placeOfSaleState}}</div><div>{{stateLabel}}</div></div>
                <div class="zip"><div>{{placeOfSaleZip}}</div><div>{{zipLabel}}</div></div>
            </ng-container>
            <ng-container *ngIf="placeOfSaleOption === 3">
                <div class="input-group street-address"><div><input type="text" pInputText [(ngModel)]="placeOfSaleStreetAddress"/></div><div>{{streetAddressLabel}}</div></div>
                <div class="input-group city"><div><input type="text" pInputText [(ngModel)]="placeOfSaleCity"/></div><div>{{cityLabel}}</div></div>
                <div class="input-group state"><div><input type="text" pInputText [(ngModel)]="placeOfSaleState"/></div><div>{{stateLabel}}</div></div>
                <div class="input-group zip"><div><input type="text" pInputText [(ngModel)]="placeOfSaleZip"/></div><div>{{zipLabel}}</div></div>
            </ng-container>
        </div>

        <div class="divider"></div>
        <div class="section-label">{{sellerSectionLabel}}</div>
        <p-dropdown [options]="poManager.current.vendor.contacts" [(ngModel)]="sellerContact" optionLabel="fullName" placeholder="Select Contact" (onChange)="onSelectedContactChanged()"></p-dropdown>
        <div class="multiple-fields">
            <div class="input-group last-name"><div><input type="text" pInputText [(ngModel)]="sellerLastName" [disabled]="sellerHasInfo('lastName')"/></div><div>{{lastNameLabel}}</div></div>
            <div class="input-group first-name"><div><input type="text" pInputText [(ngModel)]="sellerFirstName" [disabled]="sellerHasInfo('firstName')"/></div><div>{{firstNameLabel}}</div></div>
            <div class="input-group middle-initial"><div><input type="text" pInputText [(ngModel)]="sellerMiddleInitial" [disabled]="sellerHasInfo('middleInitial')"/></div><div>{{middleInitialLabel}}</div></div>
        </div>
        <div class="multiple-fields">
            <div class="input-group drivers-license-number"><div><input type="text" pInputText [(ngModel)]="sellerDriversLicenseNumber" [disabled]="sellerHasInfo('driversLicenseNumber')"/></div><div>{{sellerDriversLicenseLabel}}</div></div>
            <div class="input-group issuing-state"><div><input type="text" pInputText [(ngModel)]="sellerDriversLicenseIssuingState" [disabled]="sellerHasInfo('driversLicenseState')"/></div><div>{{issuingStateLabel}}</div></div>
            <div class="or-text">OR</div>
            <div class="ca-id-number"><div><input type="text" pInputText [(ngModel)]="sellerCaIdNumber"/></div><div>{{caIdLabel}}</div></div>
        </div>
        <div class="multiple-fields">
            <div class="input-group drivers-license-number"><div><input type="text" pInputText [(ngModel)]="sellerVehicleLicensePlateNumber"/></div><div>{{vehicleLicensePlateLabel}}</div></div>
            <div class="input-group issuing-state"><div><input type="text" pInputText [(ngModel)]="sellerVehicleLicensePlateIssuingState"/></div><div>{{issuingStateLabel}}</div></div>
        </div>

        <div class="divider"></div>
        <div class="section-label">{{buyerSectionLabel}}</div>
        <div class="buyer-name">
            <div class="last-name"><div>&nbsp;</div><div>{{lastNameLabel}}</div></div>
            <div class="first-name"><div>&nbsp;</div><div>{{firstNameLabel}}</div></div>
            <div class="middle-initial"><div>&nbsp;</div><div>{{middleInitialLabel}}</div></div>
        </div>
        <div class="buyer-address">
            <div class="street-address"><div>&nbsp;</div><div>{{streetAddressLabel}}</div></div>
            <div class="city"><div>&nbsp;</div><div>{{cityLabel}}</div></div>
            <div class="state"><div>&nbsp;</div><div>{{stateLabel}}</div></div>
            <div class="zip"><div>&nbsp;</div><div>{{zipLabel}}</div></div>
        </div>
<!--        <div class="multiple-fields">-->
<!--            <div class="input-group drivers-license-number"><div><input type="text" pInputText [(ngModel)]="buyerLicensePlateNumber" [ngModelOptions]="{standalone: true}"/></div><div>{{vehicleLicensePlateLabel}}</div></div>-->
<!--            <div class="input-group issuing-state"><div><input type="text" pInputText [(ngModel)]="buyerLicensePlateIssuingState" [ngModelOptions]="{standalone: true}"/></div><div>{{issuingStateLabel}}</div></div>-->
<!--        </div>-->

        <div class="divider"></div>
        <div class="section-label">{{descriptionSectionLabel}}</div>
        <div *ngIf="poManager.current.totalNumConverters > 0" class="item-description">
            <div class="item-type"><div>Catalytic Converters</div><div>{{itemTypeLabel}}</div></div>
            <div class="item-quantity"><div>{{poManager.current.totalNumConverters}}</div><div>{{itemQuantityLabel}}</div></div>
            <div class="item-id-number"><div>Reference Purchase Order Number: {{poManager.current.number}}</div><div>{{itemIdentificationNumberLabel}}</div></div>
        </div>
        <div *ngFor="let item of poManager.current.lineItemsMiscellaneous; let index = index" class="item-description">
            <div class="item-type"><div>{{item.description}}</div><div>{{itemTypeLabel}}</div></div>
            <div class="item-quantity"><div>{{item.quantity}}</div><div>{{itemQuantityLabel}}</div></div>
            <div class="item-id-number"><div>&nbsp;</div><div>{{itemIdentificationNumberLabel}}</div></div>
        </div>

        <div class="divider"></div>
        <div class="section-label">{{sellerStatementSectionLabel}}</div>
        <div class="seller-statement">
            <div>A.&nbsp;&nbsp;I, {{poManager.current.vendorName}}{{sellerLineAText}}</div>
            <div>B.&nbsp;&nbsp;{{sellerLineBText}} N/A</div>
        </div>

        <div class="divider"></div>
        <div class="section-label">{{thumbprintText1}}{{thumbprintText2}}{{thumbprintText3}}</div>
    </div>

    <div class="buttons">
        <div class="buttons-left">
        </div>
        <div class="buttons-right">
            <button class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            <button class="btn btn-primary" [disabled]="sellerLastName.length === 0 || sellerFirstName.length === 0 ||
                placeOfSaleStreetAddress.length === 0 || placeOfSaleCity.length === 0 || placeOfSaleState.length === 0 || placeOfSaleZip.length === 0 ||
                sellerVehicleLicensePlateNumber.length === 0 || sellerVehicleLicensePlateIssuingState.length === 0 ||
                (sellerDriversLicenseNumber.length === 0 && sellerCaIdNumber.length === 0) || (sellerDriversLicenseNumber.length > 0 && sellerDriversLicenseIssuingState.length === 0)"
                (click)="onConfirmRecordOfSalePage()">Confirm</button>
        </div>
    </div>
</div>
