import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { VendorApiService } from './vendor-api.service';
import { VendorList } from './vendor-list.model';
import { Vendor } from './vendor.model';
import { of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthManagerService } from '../auth/auth-manager.service';
import { MetalPrices } from '../pricing/metal-prices.model';
import { ProcessorTerms } from '../pricing/processor-terms.model';
import { CachedValue } from '../cached-value';
import { ImageApiService } from '../../shared-api/images/image-api.service';
import { Contact } from './contact.model';
import { UserNotificationMethod } from '../user/user.model';
import { CalculatePricesResult } from './calculate-prices.model';
import { VendorMapInfo } from './vendor-map-info.model';

@Injectable({
    providedIn: 'root'
})
export class VendorManagerService
{
    private cachedVendors: CachedValue<any>;
    private cachedHedgeInfo: CachedValue<any>;
    contactAdded = new Subject<Contact>();
    contactUpdated = new Subject<Contact>();
    contactDeleted = new Subject<number>(); // Contact ID
    vendorAdded = new Subject<Vendor>();
    vendorUpdated = new Subject<Vendor>();
    vendorDeleted = new Subject<number>(); // Vendor ID

    constructor(private authService: AuthManagerService,
                private imageApiService: ImageApiService,
                private vendorApiService: VendorApiService)
    {
        // this.broadcastService.vendorSignedOut.subscribe(() => this.clearSelectedLocation());
        this.authService.userLoggedOut.subscribe(() => this.cachedVendors = null);
    }

    getAllVendors(locationId: number, useRecentList = false, includeAssayVendors = false, includePricing = false,
                  includeHedges = false, includeSubVendors = false): Observable<VendorList>
    {
        if (useRecentList && this.cachedVendors != null && !this.cachedVendors.isExpired &&
            this.cachedVendors.value.locationId === locationId && this.cachedVendors.value.includePricing === includePricing &&
            this.cachedVendors.value.includeHedges === includeHedges &&
            this.cachedVendors.value.includeAssayVendors === includeAssayVendors &&
            this.cachedVendors.value.includeSubVendors === includeSubVendors)
        {
            return of(this.cachedVendors.value.vendorList);
        }
        return this.vendorApiService.getAllVendors(locationId, includeAssayVendors, includePricing, includeHedges, includeSubVendors)
            .pipe(tap(list => this.cachedVendors = new CachedValue<any>({ locationId, includePricing, includeHedges,
                includeAssayVendors, includeSubVendors, vendorList: list })));
    }

    getMapInfo(locationId: number): Observable<VendorMapInfo[]>
    {
        return this.vendorApiService.getMapInfo(locationId);
    }

    getVendor(vendorId): Observable<Vendor>
    {
        return this.vendorApiService.getVendor(vendorId);
    }

    getVendorWithEffectivePricing(vendorId): Observable<Vendor>
    {
        return this.vendorApiService.getVendorWithEffectivePricing(vendorId);
    }

    getHedgeInfo(locationId: number, useRecentList = false): Observable<Vendor[]>
    {
        if (useRecentList && this.cachedHedgeInfo != null && !this.cachedHedgeInfo.isExpired &&
            this.cachedHedgeInfo.value.locationId === locationId) return of(this.cachedHedgeInfo.value.vendors);
        return this.vendorApiService.getHedgeInfo(locationId)
            .pipe(tap(list => this.cachedHedgeInfo = new CachedValue<any>({ locationId, vendors: list })));
    }

    addVendor(vendor: Vendor, locationId: number): Observable<Vendor>
    {
        return this.vendorApiService.addVendor(vendor, locationId).pipe(tap(newVendor => {
            this.vendorAdded.next(newVendor);
            this.cachedVendors = null;
            this.cachedHedgeInfo = null;
        }));
    }

    updateVendor(vendor: Vendor): Observable<Vendor>
    {
        return this.vendorApiService.updateVendor(vendor).pipe(tap(updatedVendor => {
            this.vendorUpdated.next(updatedVendor);
            this.cachedVendors = null;
            this.cachedHedgeInfo = null;
        }));
    }

    deleteVendor(vendorId: number): Observable<null>
    {
        return this.vendorApiService.deleteVendor(vendorId).pipe(tap(() => {
            this.vendorDeleted.next(vendorId);
            this.cachedVendors = null;
            this.cachedHedgeInfo = null;
        }));
    }

    pricesAdded(vendorId: number, metalPrices: MetalPrices): void
    {
        const vendor = this.cachedVendors == null ? null : this.cachedVendors.value.vendorList.vendors.find(u => u.id === vendorId);
        if (vendor != null) vendor.metalPrices.unshift(metalPrices);
    }

    processorTermsAdded(vendorId: number, processorTerms: ProcessorTerms): void
    {
        const vendor = this.cachedVendors == null ? null : this.cachedVendors.value.vendorList.vendors.find(u => u.id === vendorId);
        if (vendor != null) vendor.processorTerms.unshift(processorTerms);
    }

    uploadLicenseImage(vendorId: number, file: File): Observable<any>
    {
        const parts = file.name.split('.');
        const cleanFileName = parts[0].toLowerCase().replace(/ /g, '_') + `_${new Date().getTime()}` + `.${parts[1]}`;
        return this.vendorApiService.getSasTokenUrl(cleanFileName).pipe(switchMap(model =>
        {
            if (model == null || model.token == null) return throwError('Unable to get upload URL.');

            return this.imageApiService.uploadFile(file, model.token).pipe(switchMap(result =>
            {
                if (result.status !== 201) return of(true);
                return this.vendorApiService.licenseImageUploaded(vendorId, model.token).pipe(tap(() => {
                    this.cachedVendors = null;
                    this.cachedHedgeInfo = null;
                }));
            }));
        }));
    }

    removeLicenseImage(vendorId: number, url: string): Observable<any>
    {
        return this.vendorApiService.removeLicenseImage(vendorId, url).pipe(tap(() => {
            this.cachedVendors = null;
            this.cachedHedgeInfo = null;
        }));
    }

    addContact(contact: Contact, vendorId: number): Observable<Contact>
    {
        return this.vendorApiService.addContact(contact, vendorId).pipe(tap(newContact => {
            this.contactAdded.next(newContact);
            this.cachedVendors = null;
            this.cachedHedgeInfo = null;
        }));
    }

    updateContact(contact: Contact): Observable<Contact>
    {
        return this.vendorApiService.updateContact(contact).pipe(tap(updatedContact => {
            this.contactUpdated.next(updatedContact);
            this.cachedVendors = null;
            this.cachedHedgeInfo = null;
        }));
    }

    deleteContact(contactId: number): Observable<null>
    {
        return this.vendorApiService.deleteContact(contactId).pipe(tap(() => {
            this.contactDeleted.next(contactId);
            this.cachedVendors = null;
            this.cachedHedgeInfo = null;
        }));
    }

    uploadLogo(vendorId: number, file: File): Observable<any>
    {
        const parts = file.name.split('.');
        const cleanFileName = parts[0].toLowerCase().replace(/ /g, '_') + `_${new Date().getTime()}` + `.${parts[1]}`;
        return this.vendorApiService.getSasTokenUrl(cleanFileName).pipe(switchMap(model =>
        {
            if (model == null || model.token == null) return throwError('Unable to get upload URL.');

            return this.imageApiService.uploadFile(file, model.token).pipe(switchMap((result) =>
            {
                if (result.status !== 201) return of(true);
                return this.vendorApiService.logoUploaded(vendorId, model.token);
            }));
        }));
    }

    removeLogo(vendorId: number): Observable<any>
    {
        return this.vendorApiService.removeLogo(vendorId);
    }

    uploadContactDriversLicenseImage(contactId: number, file: File): Observable<any>
    {
        const parts = file.name.split('.');
        const cleanFileName = parts[0].toLowerCase().replace(/ /g, '_') + `_${new Date().getTime()}` + `.${parts[1]}`;
        return this.vendorApiService.getSasTokenUrl(cleanFileName).pipe(switchMap(model =>
        {
            if (model == null || model.token == null) return throwError('Unable to get upload URL.');

            return this.imageApiService.uploadFile(file, model.token).pipe(switchMap((result) =>
            {
                if (result.status !== 201) return of(true);
                return this.vendorApiService.contactDriversLicenseImageUploaded(contactId, model.token);
            }));
        }));
    }

    getLicenseImageUrl(file: File): Observable<string>
    {
        const parts = file.name.split('.');
        const cleanFileName = parts[0].toLowerCase().replace(/ /g, '_') + `_${new Date().getTime()}` + `.${parts[1]}`;
        return this.vendorApiService.getSasTokenUrl(cleanFileName).pipe(switchMap(model =>
        {
            if (model == null || model.token == null) return throwError('Unable to get upload URL.');
            return this.imageApiService.uploadFile(file, model.token).pipe(switchMap(result =>
            {
                return result.status === 201 ? of(model.token) : of(null);
            }));
        }));
    }

    updatePricingNotification(vendorId: number, method: UserNotificationMethod): Observable<any>
    {
        return this.vendorApiService.updatePricingNotification(vendorId, method);
    }

    updateContractNotification(vendorId: number, method: UserNotificationMethod): Observable<any>
    {
        return this.vendorApiService.updateContractNotification(vendorId, method);
    }

    updateStatusUpdateNotification(vendorId: number, method: UserNotificationMethod): Observable<any>
    {
        return this.vendorApiService.updateStatusUpdateNotification(vendorId, method);
    }

    calculatePrices(platinumPercent: number, palladiumPercent: number, rhodiumPercent: number, weight: number):
        Observable<CalculatePricesResult>
    {
        return this.vendorApiService.calculatePrices(platinumPercent, palladiumPercent, rhodiumPercent, weight);
    }
}
