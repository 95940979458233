import { Injectable } from '@angular/core';
import { ImageProperties, jsPDF } from 'jspdf';
import { CurrencyPipe } from '@angular/common';
import { PdfSectionOutputResult } from './pdf-section-output-result';
import { Utils } from '../../services/utils.service';
import { PurchaseOrderLineItemMiscellaneous } from '../../services/purchase-order/purchase-order-line-item.model';
import { PurchaseOrderTransactionPartType } from '../../services/purchase-order/purchase-order-transaction';

@Injectable()
export class PdfWriter
{
    private pdf: jsPDF;
    private outputResult: PdfSectionOutputResult;

    dim = {
        general: {
            marginLeft: 25,
            marginTop: 20, // This should be the same as initial PdfSectionOutputResult.bottomOfOutput
            regularFontSize: 11,
            headerFontSize: 20,
            tableHeaderSize: 15,
            logoWidth: 96,
            logoHeight: 36,
            bodyWidth: 555,
            pageHeight: 800,
            footerHeight: 18,
            textPadding: 3,
            widthPerCharacter: 3.6,
        },
        header: {
            fullRowHeight: 65,
            rowSpacing: 30,
            centerMargin: 20,
            headerRightColumnWidth: 100,
            singleInfoWidth: 100,
        },

        colors: {
            darkGray: '#AAA',
            lightGray: '#CCC',
            white: 'white',
            tableHeaderColor: '#AAA',
            alternateRowColor: '#CCC',
        },

        get leftMargin(): number { return this.general.marginLeft; },
        get center(): number { return this.general.marginLeft + this.general.bodyWidth / 2; },
        get rightMargin(): number { return this.general.marginLeft + this.general.bodyWidth; },
        get width(): number { return this.general.bodyWidth; },
    };

    constructor(private currencyPipe: CurrencyPipe)
    {
        this.createNewPdf();
    }

    //region General functions
    createNewPdf(): void
    {
        this.pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'letter'
        });

        this.outputResult = new PdfSectionOutputResult();
        this.outputResult.bottomOfOutput = this.dim.general.marginTop;

        // Update the dimensions with the size created by jsPDF
        this.dim.general.bodyWidth = this.pdf.internal.pageSize.width - this.dim.general.marginLeft * 2;
        this.dim.general.pageHeight = this.pdf.internal.pageSize.height;
    }

    get leftMargin(): number { return this.dim.leftMargin; }
    get center(): number { return this.dim.center; }
    get tableLineHeight(): number { return this.pdf.getFontSize() + 4; }
    get lineHeight(): number { return this.pdf.getFontSize() / this.pdf.getLineHeightFactor(); }
    get currentPageNumber(): number { return this.outputResult.currentPageNumber; }
    textWidth(text: string): number { return this.pdf.getTextWidth(text); }

    setFont(name: string, style?: string, weight?: string): void { this.pdf.setFont(name, style, weight); }
    setFontSize(fontSize: number): void { this.pdf.setFontSize(fontSize); }
    setFontColor(color: string): void { this.pdf.setTextColor(color); }

    moveBottom(numLines: number): void { this.outputResult.bottomOfOutput += this.lineHeight * 0.8 * numLines; }

    createNewPdfPageIfNecessary(numLines = 1, headerLeft: string, headerCenter: string, headerRight: string,
                                footerLeft: string, footerCenter: string, footerRight: string): boolean
    {
        const needsPageBreak = this.outputResult.bottomOfOutput + numLines * this.lineHeight >=
            this.dim.general.pageHeight - (this.outputResult.hasFooter ? this.dim.general.footerHeight : 0);
        if (needsPageBreak)
        {
            this.createNewPdfPage(footerLeft, footerCenter, footerRight);
            if (headerLeft != null) this.outputTextLine(headerLeft);
            if (headerCenter != null) this.outputCenteredText(headerCenter);
            if (headerRight != null) this.outputRightJustifiedText(headerRight);
        }
        return needsPageBreak;
    }

    createNewPdfPage(footerLeft: string, footerCenter: string, footerRight: string): void
    {
        this.pdf.addPage();
        this.outputResult.currentPageNumber++;
        this.outputResult.hasFooter = footerLeft != null || footerCenter != null || footerRight != null;
        this.outputResult.bottomOfOutput = this.dim.general.marginTop;
        this.outputFooter(footerLeft, footerCenter, footerRight);
    }

    outputFooter(footerLeft: string, footerCenter: string, footerRight: string): void
    {
        const savedTop = this.outputResult.bottomOfOutput;
        this.outputResult.bottomOfOutput = this.dim.general.pageHeight - this.dim.general.footerHeight;
        this.setFont('times', null, 'normal');
        this.setFontSize(11);
        this.setFontColor('gray');
        if (footerLeft != null) this.outputTextLine(footerLeft);
        if (footerCenter != null) this.outputCenteredText(footerCenter);
        if (footerRight != null) this.outputRightJustifiedText(footerRight);
        this.outputResult.bottomOfOutput = savedTop;
    }
    //region

    //region Text output
    outputTextLine(text: string, indent = 0, underline = false): number
    {
        const left = this.dim.general.marginLeft + indent;
        this.pdf.text(text, left, this.outputResult.bottomOfOutput, { maxWidth: this.dim.width - indent });
        const width = this.textWidth(text);
        if (underline)
            this.pdf.line(left, this.outputResult.bottomOfOutput + 2, left + width, this.outputResult.bottomOfOutput + 2);

        return width;
    }

    outputDollarAmount(amount: number, indent = 0): number
    {
        const text = this.currencyPipe.transform(amount, 'USD');
        this.outputTextLine(text, indent);
        return this.textWidth(text);
    }

    outputTextLines(lines: string[], indent = 0, hangingIndent = 0, lineSpacingMultiplier = 1): void
    {
        let maxWidth = this.dim.general.bodyWidth - indent;
        this.pdf.text(lines[0], this.dim.general.marginLeft + indent, this.outputResult.bottomOfOutput, { maxWidth });
        let numLinesOutput = Math.ceil(this.textWidth(lines[0]) / maxWidth);
        this.outputResult.bottomOfOutput += this.lineHeight * (1 + (numLinesOutput - 1) * lineSpacingMultiplier);

        maxWidth = this.dim.general.bodyWidth - hangingIndent;
        for (let i = 1; i < lines.length; i++)
        {
            this.pdf.text(lines[i], this.dim.general.marginLeft + hangingIndent, this.outputResult.bottomOfOutput, { maxWidth });
            numLinesOutput = Math.ceil(this.textWidth(lines[i]) / maxWidth);
            this.outputResult.bottomOfOutput += this.lineHeight * (1 + (numLinesOutput - 1) * lineSpacingMultiplier);
        }
    }

    outputCenteredText(text: string): void
    {
        this.pdf.text(text, this.dim.center - this.textWidth(text) / 2, this.outputResult.bottomOfOutput);
    }

    outputRightJustifiedText(text: string): void
    {
        this.pdf.text(text, this.dim.rightMargin - this.textWidth(text), this.outputResult.bottomOfOutput);
    }
    //endregion

    //region Image functions
    getImageProperties(url: string): ImageProperties { return this.pdf.getImageProperties(url); }

    outputImage(image: any, type: string, left: number, width: number, height: number): void
    {
        if (image == null) return;
        this.pdf.addImage(image, type, left, this.outputResult.bottomOfOutput - height, width, height);
    }
    //endregion

    //region Table support
    createTableHeader(columns: any, indent = 0, tableWidth: number = null, moveToNextLine = false): void
    {
        const general = this.dim.general;
        const colors = this.dim.colors;

        if (tableWidth == null) tableWidth = general.bodyWidth - indent;

        // Draw the header
        this.setFontSize(general.regularFontSize);
        this.drawRectanglesForTableColumns(columns, indent, tableWidth, 'black', colors.white, 1);
        let currentColumnOffset = general.marginLeft + indent;
        const totalColidthPercent = Utils.sum(columns, 'widthPercent');
        for (const col of columns)
        {
            const colWidth = tableWidth * (col.widthPercent / totalColidthPercent);
            this.outputTableValue(col.title, col, tableWidth, totalColidthPercent, currentColumnOffset, this.outputResult.bottomOfOutput);
            currentColumnOffset += colWidth;
        }
        if (moveToNextLine) this.outputResult.bottomOfOutput += this.tableLineHeight;
    }

    private drawRectanglesForTableColumns(columns: any, indent = 0, tableWidth = this.dim.general.bodyWidth,
                                          drawColor = 'black', fillColor = 'white', lineWidth = 0.5): void
    {
        this.pdf.setDrawColor(drawColor == null ? fillColor : drawColor);
        this.pdf.setLineWidth(lineWidth);
        this.pdf.setFillColor(fillColor);
        let currentColumnOffset = this.dim.general.marginLeft + indent;
        const totalColumnWidthPercent = Utils.sum(columns, 'widthPercent');
        for (const col of columns)
        {
            const columnWidth = tableWidth * (col.widthPercent / totalColumnWidthPercent);
            this.pdf.rect(currentColumnOffset, this.outputResult.bottomOfOutput, columnWidth, this.tableLineHeight, 'DF');
            currentColumnOffset += columnWidth;
        }
    }

    outputTableLine(line: any, columns: any, indent = 0, tableWidth = this.dim.general.bodyWidth,
                    includeLines = false, moveToNextLine = false): void
    {
        const general = this.dim.general;

        if (includeLines) this.drawRectanglesForTableColumns(columns, indent, tableWidth);

        let currentColumnOffset = general.marginLeft + indent;
        const totalColumnWidthPercent = Utils.sum(columns, 'widthPercent');
        for (const col of columns)
        {
            const text = col.propertyName != null ? line[col.propertyName].toString() : col.calcFunc(line);
            const columnWidth = tableWidth * (col.widthPercent / totalColumnWidthPercent);
            this.outputTableValue(text, col, tableWidth, totalColumnWidthPercent, currentColumnOffset, this.outputResult.bottomOfOutput);
            currentColumnOffset += columnWidth;
        }
        if (moveToNextLine) this.outputResult.bottomOfOutput += this.tableLineHeight;
    }

    private outputTableValue(text: string, column: any, tableWidth: number, totalColWidthPercent: number, left: number, top: number): void
    {
        const general = this.dim.general;
        const columnWidth = tableWidth * (column.widthPercent / totalColWidthPercent);
        let truncatedText = text;
        if (this.textWidth(truncatedText) > columnWidth) {
            // Truncate the text
            const ellipsisWidth = this.textWidth('...');
            while (this.textWidth(truncatedText) + ellipsisWidth > columnWidth)
            {
                truncatedText = truncatedText.substring(0, truncatedText.length - 1);
            }
            truncatedText += '...';
        }
        const truncatedWidth = this.textWidth(truncatedText);
        const textLeft = column.align === 'left' ? left + 3 :
            column.align === 'right' ? left + columnWidth - truncatedWidth - general.textPadding :
                left + columnWidth / 2 - truncatedWidth / 2;
        this.pdf.text(truncatedText, textLeft, top + this.lineHeight);
    }
    //endregion

    //region Output file and blob support
    generateFile(filename: string): File
    {
        const blob = this.pdf.output('blob');
        return new File([ blob ], filename);
    }

    getBlob(): any
    {
        return this.pdf.output('blob');
    }

    getBlobUrl(): any
    {
        return this.pdf.output('bloburl').toString();
    }
    //endregion
}
