import { Utils } from '../utils.service';

export enum MetalType
{
    Platinum = 0,
    Palladium = 1,
    Rhodium = 2
}

export class MetalPrices
{
    public platinumPrice = 0;
    public palladiumPrice = 0;
    public rhodiumPrice = 0;
    public removed = true;
    public lastUpdated: Date;
    public updatedBy: string;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.lastUpdated != null) this.lastUpdated = Utils.convertApiDateStringToDate(json.lastUpdated);
        }
    }
}
