import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, of, Subject } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthApiService } from './auth-api.service';
import { LocalCacheManager } from '../../managers/local-cache.manager';
import { AuthenticatedUser } from './authenticated-user.model';
import { UserLoginModel } from './user-login';
import { Role } from './role.model';
import { UserPermissions } from './permission.model';
import { User } from '../user/user.model';
import { AdminManagerService } from '../../sections/admin/api/admin-manager.service';

@Injectable({
    providedIn: 'root'
})
export class AuthManagerService implements OnDestroy
{
    currentUser = new AuthenticatedUser();
    redirectUrl = '/parts';
    performingAutoLogin = false;

    currentUserChanged = new Subject<AuthenticatedUser>();
    userLoggedOut = new Subject();

    private allowedRolesAndPermissions: Role[];

    constructor(private adminManager: AdminManagerService,
                private authApiService: AuthApiService,
                private location: Location,
                private ngZone: NgZone,
                private router: Router)
    {
        const path = this.location.path();
        if (path.indexOf('welcome') === -1 && LocalCacheManager.rememberMe && LocalCacheManager.refreshToken)
        {
            this.performingAutoLogin = true;
            const decoded = new JwtHelperService().decodeToken(LocalCacheManager.refreshToken);
            if (decoded == null)
                this.router.navigate([ '/welcome' ]).then();

            this.loginWithRefreshToken(+decoded.LocationId).pipe(finalize(() => this.performingAutoLogin = false))
                .subscribe(user =>
                {
                    this.performingAutoLogin = false;
                    this.ngZone.run(() =>
                        this.router.navigate([ user == null ? '/welcome' : (path.length > 0 ? path : this.redirectUrl) ])).then();
                });
        }
        // this.router.events.pipe(untilDestroyed(this), filter(event => event instanceof NavigationEnd)).subscribe(event =>
        // {
        //     // Create the authenticatedUser from the last userLoginResult if it exists (i.e., the user has not logged out).
        //     const path = (event as NavigationEnd).url;
        //     if (path.indexOf('welcome') === -1 &&
        //         LocalCacheManager.rememberMe && LocalCacheManager.refreshToken && LocalCacheManager.location != null)
        //     {
        //         this.performingAutoLogin = true;
        //         this.loginWithRefreshToken(LocalCacheManager.location.id).pipe(take(1),
        //             finalize(() => this.performingAutoLogin = false))
        //             .subscribe(user =>
        //             {
        //                 this.performingAutoLogin = false;
        //                 this.router.navigate([ user == null ? '/welcome' : (path.length > 0 ? path : this.redirectUrl) ]);
        //             });
        //     }
        // });
    }

    ngOnDestroy(): void {
        /* Implemented to support untilDestroyed */
    }

    get isUserLoggedIn(): boolean { return this.currentUser.isLoggedIn; }

    get isUserLoggedInObservable(): Observable<boolean>
    {
        if (this.isUserLoggedIn) return of(true);

        return new Observable<boolean>(observer => {
            const waitForAutoLoginTimer = setInterval(() => {
                if (this.performingAutoLogin) { return; }
                clearInterval(waitForAutoLoginTimer);

                observer.next(this.isUserLoggedIn);
                observer.complete();
            }, 100);
        });
    }

    get userHasTwoFactorToken(): boolean { return this.currentUser != null && this.currentUser.twoFactorToken != null; }

    private handleLoginResponse(response: any): Observable<AuthenticatedUser> {
        this.currentUser.setAuthenticatedUser(response);
        LocalCacheManager.tokensAndExpirations = response;
        this.currentUserChanged.next(this.currentUser);
        if (this.currentUser.twoFactorToken != null || this.currentUser.locationId === 0) { return of(this.currentUser); }
        return of(this.currentUser);
        // return this.locationManager.loadCurrentLocation().pipe(map(() =>
        // {
        //     // if (this.currentUser.isAdmin) this.adminService.updateMetalPricesForLocation();
        //     return this.currentUser;
        // }));
    }

    login(model: UserLoginModel): Observable<AuthenticatedUser>
    {
        this.performingAutoLogin = false;
        return this.authApiService.loginUser(model).pipe(switchMap(response =>
        {
            return this.handleLoginResponse(response);
        }));
    }

    loginWithTwoFactorCode(code: string): Observable<AuthenticatedUser>
    {
        this.performingAutoLogin = false;
        const body = {
            TwoFactorToken: this.currentUser.twoFactorToken,
            Code: code,
        };

        return this.authApiService.loginWithTwoFactorCode(body).pipe(switchMap(response =>
        {
            return this.handleLoginResponse(response);
        }));
    }

    loginWithRefreshToken(locationId: number): Observable<AuthenticatedUser>
    {
        const body = {
            GrantType: 'refresh_token',
            RefreshToken: LocalCacheManager.refreshToken,
            LocationId: locationId
        };

        return this.authApiService.loginWithRefreshToken(body).pipe(switchMap(response =>
        {
            return this.handleLoginResponse(response);
        }));
    }

    sendTwoFactorCodeViaEmail(): Observable<AuthenticatedUser>
    {
        const body = {
            TwoFactorToken: this.currentUser.twoFactorToken,
        };
        return this.authApiService.sendTwoFactorCodeViaEmail(body).pipe(switchMap(response =>
        {
            return this.handleLoginResponse(response);
        }));
    }

    needsPhoneNumber(username: string): Observable<boolean> {
        return this.authApiService.needsPhoneNumber(username);
    }

    logout(): void
    {
        this.adminManager.resetLastTab();
        LocalCacheManager.signOut();
        this.userLoggedOut.next();
        this.redirectUrl = '/parts';

        // Let others know
        // this.broadcastService.userSignedOut.next(this.currentUser);
        //
        // this.purchaseOrderManager.currentCart.purchaseOrder = null;
        // this.currentUser.clearAuthenticatedUserModel();
        // this.locationManager.clearSelectedLocation();
        this.router.navigate([ '/welcome' ]);
    }

    addUser(user: User, locationId: number, roles: string[]): Observable<User>
    {
        return this.authApiService.addUser(user, locationId, roles);
    }

    deleteUser(userId: string): Observable<null>
    {
        return this.authApiService.deleteUser(userId);
    }

    undeleteUser(email: string): Observable<null>
    {
        return this.authApiService.undeleteUser(email);
    }

    updateUserProperties(user: User): Observable<User>
    {
        return this.authApiService.updateUserProperties(user);
    }

    updateUserRolesAndPermissions(userId: string, userPermissions: UserPermissions): Observable<User>
    {
        return this.authApiService.updateUserRolesAndPermissions(userId, userPermissions);
    }

    forgotPassword(email: string): Observable<Response>
    {
        return this.authApiService.forgotPassword(email);
    }

    resetPassword(userId: string, password: string, token: string): Observable<Response>
    {
        return this.authApiService.resetPassword(userId, password, token);
    }

    changePassword(oldPassword: string, newPassword: string): Observable<Response>
    {
        return this.authApiService.changePassword(oldPassword, newPassword);
    }

    setUserPassword(email: string, password: string): Observable<Response>
    {
        return this.authApiService.setUserPassword(email, password);
    }

    getUserPermissions(userId: string): Observable<UserPermissions>
    {
        return this.authApiService.getUserPermissions(userId);
    }

    getAllowedRolesAndPermissions(): Observable<Role[]>
    {
        if (this.allowedRolesAndPermissions != null) return of(this.allowedRolesAndPermissions);

        return this.authApiService.getAllowedRolesAndPermissions().pipe(tap(roles => this.allowedRolesAndPermissions = roles));
    }

    acceptTermsOfUse(): Observable<null>
    {
        return this.authApiService.acceptTermsOfUse().pipe(tap(() => this.currentUser.hasAcceptedTermsOfUse = true));
    }
}
