<nav *ngIf="!hideHeader" class="navbar navbar-expand-lg navbar-static-top navbar-dark">
    <div class="header-left">
        <a *ngIf="!hideNavigation" routerLink="/" class="navbar-brand" href="#">{{orgName}}</a>
    </div>

    <!-- Mobile toolbar  -->
    <div class="header-right">
        <div *ngIf="!hideNavigation && deviceManager.isMobile" class="mobile-po-container">
            <ls-purchase-order-toolbar></ls-purchase-order-toolbar>
        </div>
        <button class="navbar-toggler" type="button" (click)="isExpanded = !isExpanded" [attr.aria-expanded]="!isExpanded" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>

    <!-- Desktop toolbar   -->
    <div class="navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="!isExpanded">
        <ul *ngIf="hideNavigation" class="navbar-nav mr-auto"></ul>
        <ul *ngIf="!hideNavigation" class="navbar-nav mr-auto">
            <!-- Converters tab -->
            <li *ngIf="(!currentUser.isLocationAccountant && (!currentUser.isLimitedAdmin || currentUser.isBuyer)) || currentUser.isAnyFullAdmin" class="nav-item"><a class="nav-link" routerLink="/converters" routerLinkActive="active" (click)="menuItemClicked()">Converters</a></li>
            <li *ngIf="!currentUser.isLocationAccountant && currentUser.viewMiscParts" class="nav-item"><a class="nav-link" routerLink="/non-ferrous" routerLinkActive="active" (click)="menuItemClicked()">Non-Ferrous</a></li>
<!--            <ul class="level-two" [hidden]="!isExpanded">-->
<!--                <li class="nav-item" ><a class="nav-link" [routerLink]="['/converters/part-number']" routerLinkActive="active" (click)="menuItemClicked()">Part Number</a></li>-->
<!--                <li *ngIf="currentUser.viewCategoryParts" class="nav-item"><a class="nav-link" [routerLink]="['/converters/no-number']" routerLinkActive="active" (click)="menuItemClicked()">No Number</a></li>-->
<!--                <li *ngIf="currentUser.viewCategoryParts" class="nav-item"><a class="nav-link" [routerLink]="['/converters/categories']" routerLinkActive="active" (click)="menuItemClicked()">Categories</a></li>-->
<!--                <li *ngIf="currentUser.viewMiscParts" class="nav-item"><a class="nav-link" [routerLink]="['/converters/non-ferrous']" routerLinkActive="active" (click)="menuItemClicked()">Non-Ferrous</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" [routerLink]="['/converters/favorites']" routerLinkActive="active" (click)="menuItemClicked()">Favorites</a></li>-->
<!--            </ul>-->

            <!-- POs tab -->
            <li *ngIf="currentUser.createPurchaseOrder" class="nav-item"><a class="nav-link" routerLink="/purchaseOrders" routerLinkActive="active" (click)="menuItemClicked()">POs</a></li>

            <!-- Admin menu -->
            <li *ngIf="currentUser.isLimitedAdmin || (currentUser.isLsrAdmin && (currentUser.editUserList || currentUser.editUserProperties || currentUser.editUserPermissions || currentUser.deleteVendor || currentUser.editVendorProperties))" class="nav-item">
                <a class="nav-link" routerLink="/admin" routerLinkActive="active" (click)="menuItemClicked()">Admin</a>
            </li>

            <!-- Control Panel menu -->
            <li *ngIf="currentUser.isVendorAdmin" class="nav-item">
                <a class="nav-link" routerLink="/control-panel" routerLinkActive="active" (click)="menuItemClicked()">Control Panel</a>
            </li>
<!--            <ul *ngIf="currentUser.isAnyFullAdmin" class="level-two" [hidden]="!isExpanded">-->
<!--                <li *ngIf="currentUser.isLsrAdmin" class="nav-item" ><a class="nav-link" [routerLink]="['/admin/vendors-users']" routerLinkActive="active" (click)="menuItemClicked()">Vendors / Users</a></li>-->
<!--                <li *ngIf="currentUser.canViewPricingPage" class="nav-item"><a class="nav-link" [routerLink]="['/admin/pricing']" routerLinkActive="active" (click)="menuItemClicked()">Pricing</a></li>-->
<!--                <li *ngIf="currentUser.canViewHedgesPage" class="nav-item"><a class="nav-link" [routerLink]="['/admin/hedges']" routerLinkActive="active" (click)="menuItemClicked()">Hedges</a></li>-->
<!--                <li *ngIf="deviceManager.isDesktop && currentUser.editPartsList" class="nav-item"><a class="nav-link" [routerLink]="['/admin/upload-parts']" routerLinkActive="active" (click)="menuItemClicked()">Upload Parts</a></li>-->
<!--            </ul>-->

            <!-- Vendors menu -->
            <li *ngIf="!currentUser.isLsrAdmin && (currentUser.deleteVendor || currentUser.editVendorProperties)" class="nav-item">
                <a class="nav-link" routerLink="/vendors" routerLinkActive="active" (click)="menuItemClicked()">Vendors</a>
            </li>

            <!-- Reports menu  -->
            <li *ngIf="currentUser.isAccounting" class="nav-item">
                <a class="nav-link" routerLink="/reports" routerLinkActive="active" (click)="menuItemClicked()">Reports</a>
            </li>

            <!-- Logs menu  -->
            <li *ngIf="currentUser.createLogs" class="nav-item">
                <a class="nav-link" routerLink="/logs" routerLinkActive="active" (click)="menuItemClicked()">Logs</a>
            </li>

            <!-- Map menu  -->
            <li *ngIf="currentUser.viewMap" class="nav-item">
                <a class="nav-link" routerLink="/map" routerLinkActive="active" (click)="menuItemClicked()">Map</a>
            </li>
<!--            <ul *ngIf="currentUser.isGlobalAdmin || currentUser.isLocationAdmin" class="level-two" [hidden]="!isExpanded">-->
<!--                <li class="nav-item"><a class="nav-link" [routerLink]="['/reports/users']" routerLinkActive="active" (click)="menuItemClicked()">Users</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" [routerLink]="['/reports/sales']" routerLinkActive="active" (click)="menuItemClicked()">Sales</a></li>-->
<!--            </ul>-->
        </ul>

        <ul class="navbar-nav right-nav">
            <li *ngIf="currentUser.isGlobalAdmin || (currentUser.isLocationAccountant && currentUser.additionalLocationIds.length > 0)">
                <ls-location-toolbar></ls-location-toolbar>
            </li>
            <li *ngIf="!deviceManager.isMobile"><ls-purchase-order-toolbar></ls-purchase-order-toolbar></li>
            <li *ngIf="isExpanded" class="nav-item"><a class="nav-link" routerLink="/auth/profile" routerLinkActive="active" (click)="menuItemClicked()"><span *ngIf="deviceManager.isMobile">Profile: </span>{{currentUser.fullName}}</a></li>
<!--            <ul class="level-two" [hidden]="!isExpanded">-->
<!--                <li class="nav-item"><a class="nav-link" routerLink="/auth/change-password" routerLinkActive="active" (click)="menuItemClicked()">Change Password</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" routerLink="/auth/profile" routerLinkActive="active" (click)="menuItemClicked()">Profile</a></li>-->
                <li *ngIf="isExpanded" class="nav-item"><a class="nav-link" (click)="logOut()">Log Out</a></li>
<!--            </ul>-->
            <p-menubar *ngIf="!isExpanded" [model]="userMenuItems" styleClass="header-dropdown dark" [autoDisplay]="false"></p-menubar>
        </ul>
    </div>
</nav>
