import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';

import { PurchaseOrderManagerService } from '../../../../services/purchase-order/purchase-order-manager.service';
import { PurchaseOrderPDFComponent } from '../../purchase-order-pdf/purchase-order-pdf.component';
import { CaptureSignatureComponent } from '../../capture-signature/capture-signature.component';
import { ThemeManager } from '../../../../managers/theme.manager';
import { AuthManagerService } from '../../../../services/auth/auth-manager.service';

@Component({
    selector: 'ls-illinois-compliance-page',
    templateUrl: './illinois-compliance-page.component.html',
    styleUrls: [ './illinois-compliance-page.component.less' ]
})
export class IllinoisCompliancePageComponent implements OnInit
{
    @ViewChild('signature', { static: false }) signature: CaptureSignatureComponent;

    @Output() signatureCompleted = new EventEmitter<boolean>();

    vehicleLicenseNum = '';
    vehicleLicenseState = '';
    idNum = '';
    idState = '';
    name = '';
    date: Date;

    headerText = 'Purchase Agreement';

    introRegularText1 = 'IN CONSIDERATION OF THE COVENANTS and agreements contained in this Purchase Agreement, the';
    introRegularText2 = 'parties to this Agreement agree as follows:';
    partiesText1 = ' (the "Seller"), of';
    partiesText2 = 'does hereby sell, assign and transfer to';
    partiesText3 = 'Legend Smelting And Recycling, LLC (the "Buyer"), ';
    partiesText4 = 'the following property...';
    sellerInfoHeaderText = 'Seller information:';
    vehicleLicenseText = 'Vehicle License # __________________________________________';
    idText = 'ID or DL # _______________________________________________';
    stateText = 'State _______________';
    signaturePrelude1 = 'I, the undersigned, affirm under penalty of law that the property that is subject to this transaction is not';
    signaturePrelude2 = 'to the best of my knowledge stolen property. I certify that I am the legal owner of all merchandise sold.';
    signatureText = 'Signature ______________________________________________________';
    printText = 'Print _________________________________________________________';
    dateText = 'Date ___________________________';

    columns = [
        { title: 'PO #', widthPercent: 35 },
        { title: 'Description', widthPercent: 15 },
        { title: 'Quantity', widthPercent: 15 },
        { title: 'Purchase Price', widthPercent: 15 },
    ];

    ThemeManager = ThemeManager;

    constructor(public poManager: PurchaseOrderManagerService, private authManager: AuthManagerService, private currencyPipe: CurrencyPipe) { }

    ngOnInit(): void
    {
    }

    generateSignaturePage(parent: PurchaseOrderPDFComponent): void
    {
        const vendor = parent.po.vendor;

        // Add Title
        parent.outputComplianceHeader(this.headerText);

        // ---------- SECTION 1 START ----------
        parent.pdf.setFontSize(parent.pdfDimensions.general.regularFontSize);
        parent.pdf.setFont('helvetica', null, 'normal');
        parent.outputTextLine(this.introRegularText1);
        parent.outputTextLine(this.introRegularText2);
        // ---------- END SECTION 1 ----------


        // ---------- SECTION 2 START ----------
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(`${vendor.name} ${this.partiesText1} ${vendor.fullAddress},`);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(this.partiesText2);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        if (this.authManager.currentUser.purchaseOrderLocationName != null)
            parent.outputTextLine(`${this.partiesText3} ${this.authManager.currentUser.purchaseOrderAddress} ${this.authManager.currentUser.purchaseOrderCityStateZip}`);
        else
            parent.outputTextLine(`${this.partiesText3} ${parent.po.location.fullAddress}`);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(this.partiesText4);
        // ---------- END SECTION 2 ----------

        // ---------- SECTION 3 START ----------
        // Use "table headers" to create the pseudo-table
        parent.createTableHeader(this.columns, true, false);
        const columnOutput = cloneDeep(this.columns);
        columnOutput[0].title = parent.po.number;
        columnOutput[1].title = 'Converters';
        columnOutput[2].title = parent.po.totalNumConverters.toString();
        columnOutput[3].title = this.currencyPipe.transform(parent.po.totalConvertersPrice, 'USD');
        parent.createTableHeader(columnOutput, true, false);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        // ---------- END SECTION 3 ----------


        // tslint:disable:max-line-length

        // ---------- SECTION 4 START ----------
        parent.result.bottomOfOutput += parent.lineHeight * 3;
        parent.outputTextLine(this.sellerInfoHeaderText);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`${this.vehicleLicenseText}  ${this.stateText}`);
        parent.outputText(this.vehicleLicenseNum, parent.textWidth(this.removeUnderscores(this.vehicleLicenseText) + 5));
        parent.outputTextLine(this.vehicleLicenseState, parent.textWidth(`${this.vehicleLicenseText}  `) +
            parent.textWidth(this.removeUnderscores(this.stateText) + 5));

        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`${this.idText}  ${this.stateText}`);
        parent.outputText(this.idNum, parent.textWidth(this.removeUnderscores(this.idText) + 5));
        parent.outputTextLine(this.idState, parent.textWidth(`${this.idText}  `) +
            parent.textWidth(this.removeUnderscores(this.stateText) + 5));
        // ---------- END SECTION 4 ----------

        // ---------- SECTION 5 START ----------
        parent.result.bottomOfOutput += parent.lineHeight * 5;
        parent.outputTextLine(this.signaturePrelude1);
        parent.outputTextLine(this.signaturePrelude2);

        parent.result.bottomOfOutput += parent.lineHeight * 1.5;
        const imageHeight = parent.lineHeight * 2;
        const widthToHeightRatio = this.signature.widthToHeightRatio;
        parent.outputImage(this.signature.signature, 'PNG', parent.leftMargin + parent.textWidth(this.removeUnderscores(this.signatureText)), imageHeight * widthToHeightRatio, imageHeight);
        parent.outputTextLine(this.signatureText);

        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`${this.printText}  ${this.dateText}`);
        parent.outputText(this.name, parent.textWidth(this.removeUnderscores(this.printText) + 5));
        parent.outputTextLine(moment(this.date).format('MM-DD-yyyy'), parent.textWidth(`${this.printText}  `) +
            parent.textWidth(this.removeUnderscores(this.dateText) + 5));
        // ---------- END SECTION 6 ----------
        // tslint:enable:max-line-length

        this.clearSignatures();
    }

    get partiesTextHTML(): string
    {
        const vendor = this.poManager.current.vendor;
        return `<div class="party-line">${vendor.name} ${this.partiesText1} ${vendor.fullAddress},</div>` +
            `<div>${this.partiesText2}</div>` + `<div>${this.partiesText3} ` +
            (this.authManager.currentUser.purchaseOrderLocationName != null ?
                `${this.authManager.currentUser.purchaseOrderAddress} ${this.authManager.currentUser.purchaseOrderCityStateZip}` :
                `${this.poManager.current.location.fullAddress}</div>`) +
            `<div>${this.partiesText4}</div>`;
    }

    get signaturePrelude(): string
    {
        return `${this.signaturePrelude1} ${this.signaturePrelude2}`;
    }

    removeUnderscores(text: string): string
    {
        return text.replace(/_/g, '');
    }

    clearSignatures(): void
    {
        this.signature.clear();
    }

    onCancel(): void
    {
        this.signatureCompleted.emit(false);
    }

    onSave(): void
    {
        this.signatureCompleted.emit(true);
    }
}
