import { Utils } from '../utils.service';

export class Contact
{
    public id = 0;
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public driversLicenseNumber: string;
    public driversLicenseState: string;
    public stateIdCardNumber: string;
    public email: string;
    public phone: string;
    public driversLicenseImageUrl: string;
    public driversLicenseExpiration: Date;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.driversLicenseExpiration != null)
                this.driversLicenseExpiration = Utils.convertApiDateStringToDate(json.driversLicenseExpiration);
        }
    }

    get fullName(): string { return `${this.firstName || ''} ${this.lastName || ''}`.trim(); }
}
