import { PurchaseOrder } from './purchase-order.model';

export class PurchaseOrdersList
{
    public purchaseOrders: PurchaseOrder[] = [];
    public count: number;

    constructor(json: any)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            if (json.purchaseOrders) this.purchaseOrders = json.purchaseOrders.map(item => new PurchaseOrder(item));
        }
    }
}
