import { Utils } from '../utils.service';

export class UserLog
{
    public id = 0;
    public buyerName: string;
    public createdOn: Date;
    public title: string;
    public description: string;
    public vendorId: number;
    public vendorName: string;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.createdOn != null) this.createdOn = Utils.convertApiDateStringToDate(json.createdOn);
        }
    }
}
