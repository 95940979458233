import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export let AppInjector: Injector;

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgbModule
    ]
})
export class CoreModule
{
    constructor(injector: Injector)
    {
        AppInjector = injector;
    }
}
