import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ls-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: [ './table-header.component.less' ]
})
export class TableHeaderComponent implements OnInit
{
    @Input() columns: any[];

    constructor() { }

    ngOnInit(): void
    {
    }
}
