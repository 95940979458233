<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<button *ngIf="vendorId != null" class="btn btn-success" (click)="closeView()">Close</button>

<div *ngIf="log != null && log.length === 0 && !busy" class="no-data">
    There are no log entries.
</div>

<div *ngIf="deviceManager.isMobile" class="mobile-list">
    <div class="mobile-table">
        <div *ngFor="let logEntry of log" class="card" (click)="onLogSelected(userLog)">
            <div class="date-and-delete">
                <div>
                    <div><span class="label">Date:</span>{{logEntry.createdOn | date:'MM/dd/yy'}}</div>
                    <div><span class="label">Log #:</span>{{logEntry.id}}</div>
                </div>
                <button *ngIf="authManager.currentUser.deletePurchaseOrder" class="btn delete-button" title="Delete Log Entry" (click)="onDeleteLogEntry(logEntry)"><i class="fas fa-trash"></i></button>
            </div>
            <div><span class="label">Buyer:</span>{{logEntry.buyerName}}</div>
            <div><span class="label">Title:</span>{{logEntry.title}}</div>
            <div><span class="label">Vendor:</span>{{logEntry.vendorName}}</div>
            <div><span class="label">Description:</span>{{logEntry.description}}</div>
        </div>
    </div>
</div>

<div *ngIf="!deviceManager.isMobile" class="desktop-table-container">
    <div class="inner-table-container">
        <p-table *ngIf="log != null && log.length > 0" [value]="log" styleClass="list-table" [columns]="columns" sortField="id" sortOrder="-1" [scrollable]="true" scrollHeight="flex"
                 selectionMode="single" [(selection)]="selectedLog" (onRowSelect)="onLogSelected($event.data)" (onRowUnselect)="onLogSelected(null)">
            <ng-template pTemplate="header">
                <!-- Column Titles -->
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="col.sortDisabled || false" [style.width]="col.width">
                        <div>
                            {{col.header}}
                            <p-sortIcon *ngIf="!(col.sortDisabled || false)" [field]="col.field"></p-sortIcon>
                            <p-columnFilter *ngIf="!(col.filterDisabled || false)" type="text" [field]="col.field" display="menu"
                                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                            [matchMode]="'contains'">
                            </p-columnFilter>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-logEntry let-index="rowIndex">
                <tr [pSelectableRow]="logEntry" [class]="index % 2 === 0 ? 'even' : 'odd'">
                    <td *ngFor="let col of columns" [ngSwitch]="col.field" [style.width]="col.width" [style]="col.style">
                        <div *ngSwitchCase="'title'" class="description">{{logEntry[col.field]}}</div>
                        <div *ngSwitchCase="'description'" class="description">{{logEntry[col.field]}}</div>
                        <div *ngSwitchCase="'createdOn'">{{logEntry.createdOn | date:'MM/dd/yy'}}</div>
                        <div *ngSwitchCase="'deleteButton'" style="margin-right: 10px;">
                            <button class="btn delete-button" title="Delete Log Entry" (click)="onDeleteLogEntry(logEntry)"><i class="fas fa-trash"></i></button>
                        </div>
                        <div *ngSwitchDefault>{{logEntry[col.field]}}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div *ngIf="selectedLog != null" class="selected-log">
        <div><span class="label">Date:</span>{{selectedLog.createdOn | date:'MM/dd/yy'}}</div>
        <div><span class="label">Log #:</span>{{selectedLog.id}}</div>
        <div><span class="label">Buyer:</span>{{selectedLog.buyerName}}</div>
        <div><span class="label">Title:</span>{{selectedLog.title}}</div>
        <div><span class="label">Vendor:</span>{{selectedLog.vendorName}}</div>
        <div><span class="label">Description:</span>{{selectedLog.description}}</div>
    </div>
</div>

<ls-waiting-indicator *ngIf="busy || error" [error]="error" [@fadeInOut] (okClick)="onErrorDismissed()"></ls-waiting-indicator>
