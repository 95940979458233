import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiEndpointInfo } from './api-endpoint-info.model';
import { timeout } from 'rxjs/operators';

export enum HttpMethod
{
    Get,
    Post,
    Put,
    Patch,
    Delete
}

@Injectable({
    providedIn: 'root'
})
export class ApiService
{
    private defaultEndpointIndex = environment.defaultApiEndpoint;
    private apiEndpoints: ApiEndpointInfo[] = [
        new ApiEndpointInfo('Legend-V2Production', 'https://api.legenddatabase.com/api/'),
        new ApiEndpointInfo('Legend-V2Staging', 'https://apistaging.legenddatabase.com/api/'),
        new ApiEndpointInfo('Legend-V2Test', 'https://apitest.legenddatabase.com/api/'),
        new ApiEndpointInfo('Legend-Demo', 'https://apidemo.legenddatabase.com/api/'),
        new ApiEndpointInfo('Legend-Dev', 'https://localhost:5001/api/'),
        new ApiEndpointInfo('Legend-WhiteLabel-Dev', 'https://localhost:5001/api/'),
    ];
    protected baseApiUrl = this.apiEndpoints[this.defaultEndpointIndex].baseUrl;

    constructor(protected http: HttpClient)
    {
    }

    private readonly headers = new HttpHeaders().set('Content-Type', 'application/json');

    apiRequest<T>(url: string, httpMethod: HttpMethod, httpBody?: any, timeoutInSecs = 15): Observable<T>
    {
        if (url.length > 2080)
        {
            const urlWithoutParams = url.split('?');
            throw new URIError(`apiRequest URI (${urlWithoutParams[0]}) is too long. This may indicate an API error. Consult the factory.`);
        }
        return this.http.request<T>(HttpMethod[httpMethod], url, { headers: this.headers, body: httpBody })
            .pipe(timeout(timeoutInSecs * 1000));
    }

    apiUploadRequest<T>(url: string, formData: FormData, timeoutInSecs: number): Observable<T>
    {
        return this.http.post<T>(url, formData).pipe(timeout(timeoutInSecs * 1000));
    }
}
