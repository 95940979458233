import { AfterContentChecked, AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { CurrencyPipe } from '@angular/common';

import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';

import { CaptureSignatureComponent } from '../../capture-signature/capture-signature.component';
import { PurchaseOrderManagerService } from '../../../../services/purchase-order/purchase-order-manager.service';
import { PurchaseOrderPDFComponent } from '../../purchase-order-pdf/purchase-order-pdf.component';
import { ThemeManager } from '../../../../managers/theme.manager';

@Component({
    selector: 'ls-west-virginia-compliance-page',
    templateUrl: './west-virginia-compliance-page.component.html',
    styleUrls: [ './west-virginia-compliance-page.component.less' ]
})
export class WestVirginiaCompliancePageComponent implements OnInit, AfterViewInit
{
    @ViewChild('sellerSignature', { static: false }) sellerSignature: CaptureSignatureComponent;
    @ViewChild('purchaserSignature', { static: false }) purchaserSignature: CaptureSignatureComponent;

    @Output() signatureCompleted = new EventEmitter<boolean>();

    date: Date;
    name = '';

    headerLeftText1 = 'WVSP Form 90';
    headerLeftText2 = 'REV 12/07';
    headerLeftText3 = 'Amended';

    headerCenterTextTitle = 'Purchasers of Scrap Metal';
    headerCenterTextInfo = '(PRINT OR TYPE ALL INFORMATION IN FULL)';

    headerRightText = 'Completed Forms Shall be kept on file or forwarded to your local West Virginia State Police Detachment ' +
        'and the Chief of Police of the municipality or the Sheriff of the County where the transaction occurred.';

    introRegularText1 = 'IN CONSIDERATION OF THE COVENANTS contained in this Wholesale';
    introRegularText2 = 'Agreement, the parties to this Agreement agree the "Seller does hereby sell, ';
    introRegularText3 = 'assign and transfer to Legend Smelting And Recycling, LLC (the "Buyer"),';
    introRegularText4 = 'of 717 O\'Neill Dr Hebron, OH 43025 the property described below';

    fullNameOfSellerText = 'Full Name of Seller: __________________________________________________________';
    fullNameOfSeller = '';
    homeAddressText = 'Home Address: ______________________________________________________________';
    homeAddress = '';
    sellersHomeCountyText = 'County: _____________________';
    sellersHomeCounty = '';
    sellersHomeZipText = 'Zip: __________';
    sellersHomeZip = '';
    sellersHomePhoneNumberText = 'Home Phone Number: ________________';
    sellersHomePhoneNumber = '';

    sellersBusinessAddressText = 'Seller\'s Business Address: ______________________________________________________';
    sellersBusinessAddress = '';
    sellersBusinessCountyText = 'County: _____________________';
    sellersBusinessCounty = '';
    sellersBusinessZipText = 'Zip: __________';
    sellersBusinessZip = '';
    sellersBusinessPhoneNumberText = 'Business Phone Number: ______________';
    sellersBusinessPhoneNumber = '';

    descriptionForTransportText = 'Description of Motor Vehicle used for Transport:';
    makeText = 'Make: _______________';
    make = '';
    modelText = 'Model: _______________';
    model = '';
    licenseNumberText = 'License #: __________';
    licenseNumber = '';
    stateOfIssueText = 'State of Issue: _______';
    stateOfIssue = '';
    colorText = 'Color: _______________';
    color = '';

    dateOfTransactionText = 'Date of Transaction: ___________________';
    dateOfTransaction = '';
    timeOfTransactionText = 'Time of Transaction: ___________________';
    timeOfTransaction = '';

    descriptionOfKindText = 'Description of the kind of Scrap Metal:';
    purchasedText = 'Purchased: ___________________';
    characterText = 'Character: ___________________';
    weightText = 'Weight: ___________________';
    descriptions = [
        { purchased: '', character: '', weight: '' },
        { purchased: '', character: '', weight: '' },
        { purchased: '', character: '', weight: '' },
        { purchased: '', character: '', weight: '' },
        { purchased: '', character: '', weight: '' },
    ];

    wasMetalText = 'Was the Scrap Metal:';
    purchasedOptionText = 'Purchased';
    purchasedOption = true;
    collateralOptionText = 'Taken as Collateral';
    collateralOption = false;
    consignmentText = 'Taken on Consignment';
    consignmentOption = false;

    section7Text = 'In accordance with West Virginia Code CHAPTER 61, ARTICLE 3, &sect;61-3-49(e), this purchase was made at wholesale.' +
        ' Thus, we are exempt from obtaining "(a) signed certificate of ownership of the scrap metal being sold or a signed authorization' +
        ' from the owner" or "(a) photocopy of a valid [West Virginia] license or [ID] cart" or "any other valid [form of government]' +
        ' photo [ID] under subsection (d) of that section.';

    section9Text = 'I, the seller, certify that I am authorized to possess and sell the scrap metal being sold. I am in lawful' +
        ' possession of the scrap metal being sold, and I am the lawful owner of the scrap metal or I have the Lawful owner\'s' +
        ' permission to sell the scrap metal.';

    purchaserSignatureText = 'Purchaser\'s Signature: _____________________________________';
    sellerSignatureText = 'Seller\'s Signature: _____________________________________';
    fingerprintText1 = 'Seller\'s Index Finger or Thumbprint';
    fingerprintText2 = '(Catalytic Converters)';

    ThemeManager = ThemeManager;

    constructor(public poManager: PurchaseOrderManagerService) { }

    ngOnInit(): void
    {
    }

    ngAfterViewInit(): void {
        if (this.poManager.current == null) return;

        setTimeout(() =>
        {
            const poDate = moment(this.poManager.current.purchaseDate);
            this.dateOfTransaction = poDate.format('MM-DD-yyyy');
            this.timeOfTransaction = poDate.format('h:mm a');
            if (this.poManager.current.vendor != null)
            {
                this.sellersBusinessAddress = this.poManager.current.vendor.address || '';
                this.sellersBusinessZip = this.poManager.current.vendor.zip || '';
                this.sellersBusinessPhoneNumber = this.poManager.current.vendor.mainPhone || '';
            }
        });
    }

    generateSignaturePage(parent: PurchaseOrderPDFComponent): void
    {
        // tslint:disable:max-line-length
        const spacingBetweenSections = parent.lineHeight * 0.9;
        const spacingBetweenLines = parent.lineHeight;

        // Add Header
        // Header-left
        const centerForText = parent.center - parent.leftMargin;
        parent.pdf.setFontSize(11);
        parent.outputTextLines([ this.headerLeftText1, this.headerLeftText2, this.headerLeftText3 ]);
        // Header-center
        parent.result.bottomOfOutput -= parent.lineHeight * 3;
        parent.pdf.addImage('assets/images/west_virginia_state_police_logo.png', 'PNG',
            parent.center - 15, parent.result.bottomOfOutput, 30, 30);
        parent.result.bottomOfOutput += 43;
        parent.pdf.setFontSize(16);
        parent.outputTextLine(this.headerCenterTextTitle, centerForText - parent.textWidth(this.headerCenterTextTitle) / 2);
        parent.result.bottomOfOutput -= parent.lineHeight / 2;
        parent.pdf.setFontSize(9);
        parent.outputTextLine(this.headerCenterTextInfo, centerForText - parent.textWidth(this.headerCenterTextInfo) / 2);
        parent.result.bottomOfOutput += parent.lineHeight;
        parent.pdf.setFontSize(10);
        parent.pdf.text([ this.introRegularText1, this.introRegularText2, this.introRegularText3, this.introRegularText4 ],
            parent.center, parent.result.bottomOfOutput, { align: 'center' });
        parent.result.bottomOfOutput += parent.lineHeight * 3;
        // Header-right
        parent.pdf.setFontSize(6);
        parent.pdf.text(this.headerRightText, parent.rightMargin, parent.top, { align: 'right', maxWidth: parent.width * 0.15 });
        parent.pdf.rect(parent.rightMargin - parent.width * 0.15, parent.top - 4, parent.width * 0.16, 43);

        // Seller's personal information (section 1)
        parent.pdf.setFontSize(12);
        parent.result.bottomOfOutput += spacingBetweenSections;
        parent.outputText(`1.`, -10);
        parent.outputText(this.fullNameOfSellerText);
        parent.outputText(this.fullNameOfSeller, parent.textWidth(this.removeUnderscores(this.fullNameOfSellerText) + 5));

        parent.result.bottomOfOutput += spacingBetweenLines;
        parent.outputText(this.homeAddressText);
        parent.outputText(this.homeAddress, parent.textWidth(this.removeUnderscores(this.homeAddressText) + 5));

        parent.result.bottomOfOutput += spacingBetweenLines;
        parent.outputText(`${this.sellersHomeCountyText}  ${this.sellersHomeZipText}  ${this.sellersHomePhoneNumberText}`);
        parent.outputText(this.sellersHomeCounty, parent.textWidth(this.removeUnderscores(this.sellersHomeCountyText)) + 5);
        parent.outputText(this.sellersHomeZip, parent.textWidth(`${this.sellersHomeCountyText}  `) +
            parent.textWidth(this.removeUnderscores(this.sellersHomeZipText)) + 5);
        parent.outputTextLine(this.sellersHomePhoneNumber, parent.textWidth(`${this.sellersHomeCountyText}  `) +
            parent.textWidth(`${this.sellersHomeZipText}  `) +
            parent.textWidth(this.removeUnderscores(this.sellersHomePhoneNumberText)) + 5);

        // Seller's business information (section 2)
        parent.result.bottomOfOutput += spacingBetweenSections;
        parent.outputText(`2.`, -10);
        parent.outputText(this.sellersBusinessAddressText);
        parent.outputText(this.sellersBusinessAddress, parent.textWidth(this.removeUnderscores(this.sellersBusinessAddressText) + 5));
        parent.result.bottomOfOutput += spacingBetweenLines;
        parent.outputText(`${this.sellersBusinessCountyText}  ${this.sellersBusinessZipText}  ${this.sellersBusinessPhoneNumberText}`);
        parent.outputText(this.sellersBusinessCounty, parent.textWidth(this.removeUnderscores(this.sellersBusinessCountyText)) + 5);
        parent.outputText(this.sellersBusinessZip, parent.textWidth(`${this.sellersBusinessCountyText}  `) +
            parent.textWidth(this.removeUnderscores(this.sellersBusinessZipText)) + 5);
        parent.outputTextLine(this.sellersBusinessPhoneNumber, parent.textWidth(`${this.sellersBusinessCountyText}  `) +
            parent.textWidth(`${this.sellersBusinessZipText}  `) +
            parent.textWidth(this.removeUnderscores(this.sellersBusinessPhoneNumberText)) + 5);

        // Motor Vehicle Description (section 3)
        parent.result.bottomOfOutput += spacingBetweenSections;
        parent.outputText(`3.`, -10);
        parent.outputText(this.descriptionForTransportText);
        parent.result.bottomOfOutput += spacingBetweenLines;
        parent.outputText(`${this.makeText}  ${this.modelText}  ${this.licenseNumberText}  ${this.stateOfIssueText}`);
        parent.outputText(this.make, parent.textWidth(this.removeUnderscores(this.makeText)) + 5);
        parent.outputText(this.model, parent.textWidth(`${this.makeText}  `) +
            parent.textWidth(this.removeUnderscores(this.modelText)) + 5);
        parent.outputText(this.licenseNumber, parent.textWidth(`${this.makeText}  `) +
            parent.textWidth(`${this.modelText}  `) +
            parent.textWidth(this.removeUnderscores(this.licenseNumberText)) + 5);
        parent.outputText(this.stateOfIssue, parent.textWidth(`${this.makeText}  `) +
            parent.textWidth(`${this.modelText}  `) +
            parent.textWidth(`${this.licenseNumberText}  `) +
            parent.textWidth(this.removeUnderscores(this.stateOfIssueText)) + 5);
        parent.result.bottomOfOutput += spacingBetweenLines;
        parent.outputText(`${this.colorText}`);
        parent.outputTextLine(this.color, parent.textWidth(this.removeUnderscores(this.colorText)) + 5);

        // Time of Transaction (section 4)
        parent.result.bottomOfOutput += spacingBetweenSections;
        parent.outputText(`4.`, -10);
        parent.outputText(`${this.dateOfTransactionText}  ${this.timeOfTransactionText}`);
        parent.outputText(this.dateOfTransaction, parent.textWidth(this.removeUnderscores(this.dateOfTransactionText)) + 5);
        parent.outputTextLine(this.timeOfTransaction, parent.textWidth(`${this.dateOfTransactionText}  `) +
            parent.textWidth(this.removeUnderscores(this.timeOfTransactionText)) + 5);

        // Description (section 5)
        parent.result.bottomOfOutput += spacingBetweenSections;
        parent.outputText(`5.`, -10);
        parent.outputText(this.descriptionForTransportText);
        parent.result.bottomOfOutput += spacingBetweenLines;
        for (const line of this.descriptions)
        {
            parent.outputText(`${this.purchasedText}  ${this.characterText}  ${this.weightText}`);
            parent.outputText(line.purchased, parent.textWidth(this.removeUnderscores(this.purchasedText)) + 5);
            parent.outputText(line.character, parent.textWidth(`${this.purchasedText}  `) +
                parent.textWidth(this.removeUnderscores(this.characterText)) + 5);
            parent.outputTextLine(line.weight, parent.textWidth(`${this.purchasedText}  `) +
                parent.textWidth(`${this.characterText}  `) +
                parent.textWidth(this.removeUnderscores(this.weightText)) + 5);
        }

        // Purchased or Other (section 6)
        parent.result.bottomOfOutput += spacingBetweenSections;
        parent.outputText(`6.`, -10);
        parent.outputText(`${this.wasMetalText}`);
        let margin = parent.textWidth(this.wasMetalText) + 5;
        parent.outputText(this.purchasedOptionText, margin);
        margin += parent.textWidth(this.purchasedOptionText) + 5;
        parent.outputCheckbox(margin, this.purchasedOption);
        margin += 20;
        parent.outputText(this.collateralOptionText, margin);
        margin += parent.textWidth(this.collateralOptionText) + 5;
        parent.outputCheckbox(margin, this.collateralOption);
        margin += 20;
        parent.outputText(this.consignmentText, margin);
        margin += parent.textWidth(this.consignmentText) + 5;
        parent.outputCheckbox(margin, this.consignmentOption);

        // Section 7
        parent.result.bottomOfOutput += spacingBetweenSections;
        parent.outputText(`7.`, -10);
        parent.outputText(this.section7Text, 0, { maxWidth: parent.width });
        parent.result.bottomOfOutput += parent.lineHeight * 4;

        // Purchaser Signature (section 8)
        parent.result.bottomOfOutput += spacingBetweenSections + parent.lineHeight;
        const imageHeight = parent.lineHeight * 1.5;
        const widthToHeightRatio = this.purchaserSignature.widthToHeightRatio;
        parent.outputText(`8.`, -10);
        parent.outputText(`${this.purchaserSignatureText}`);
        parent.outputImage(this.purchaserSignature.signature, 'PNG', parent.leftMargin + parent.textWidth(this.removeUnderscores(this.purchaserSignatureText)), imageHeight * widthToHeightRatio, imageHeight);

        // Section 9
        parent.result.bottomOfOutput += spacingBetweenSections;
        parent.outputText(`9.`, -10);
        parent.outputText(this.section9Text, 0, { maxWidth: parent.width });
        parent.result.bottomOfOutput += parent.lineHeight * 2;

        // Seller Signature (section 10)
        parent.result.bottomOfOutput += spacingBetweenSections + parent.lineHeight;
        parent.outputText(`10.`, -14);
        parent.outputText(`${this.sellerSignatureText}`);
        parent.outputImage(this.sellerSignature.signature, 'PNG', parent.leftMargin + parent.textWidth(this.removeUnderscores(this.sellerSignatureText)), imageHeight * widthToHeightRatio, imageHeight);

        // Fingerprint
        parent.pdf.setFontSize(7);
        parent.pdf.setDrawColor(0);
        parent.result.bottomOfOutput -= parent.lineHeight * 2;
        parent.pdf.roundedRect(parent.rightMargin - 70, parent.result.bottomOfOutput - 15, 80, 40, 5, 5, 'S');
        margin = parent.rightMargin - 100 - parent.textWidth(this.fingerprintText2);
        parent.outputText(this.fingerprintText1, margin, { maxWidth: parent.textWidth(this.fingerprintText2) });
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(this.fingerprintText2, margin);

        // tslint:enable:max-line-length

        this.clearSignatures();
    }

    removeUnderscores(text: string): string
    {
        return text.replace(/_/g, '');
    }

    clearSignatures(): void
    {
        this.purchaserSignature.clear();
        this.sellerSignature.clear();
    }

    onCancel(): void
    {
        this.signatureCompleted.emit(false);
    }

    onSave(): void
    {
        this.signatureCompleted.emit(true);
    }
}
