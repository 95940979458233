import { Utils } from '../utils.service';

export class ProcessorTerms
{
    public platinumPercentReturn = 0;
    public palladiumPercentReturn = 0;
    public rhodiumPercentReturn = 0;
    public platinumFeePerOunce = 0;
    public palladiumFeePerOunce = 0;
    public rhodiumFeePerOunce = 0;
    public processingFeePerPound = 0;
    public removed = true;
    public lastUpdated: Date;
    public updatedBy: string;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.lastUpdated != null) this.lastUpdated = Utils.convertApiDateStringToDate(json.lastUpdated);
        }
    }
}
