import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { ApiService, HttpMethod } from '../api.service';
import { UserLoginModel } from './user-login';
import { AuthTokenResponse } from '../../models/auth-token-response';
import { LocalCacheManager } from '../../managers/local-cache.manager';
import { User } from '../user/user.model';
import { Role } from './role.model';
import { UserPermissions } from './permission.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService extends ApiService
{
    constructor(protected http: HttpClient)
    {
        super(http);
    }

    private static getEncodedEmail(email: string): string
    {
        return email.replace('+', '%2b');
    }

    // =============================================================================================================================
    // Authentication including password management
    // =============================================================================================================================
    loginUser(model: UserLoginModel): Observable<AuthTokenResponse>
    {
        return this.getIP().pipe(switchMap(result => this.loginWithIp(model, result == null ? null : result.ip)));
    }

    private getIP(useSecondary = false): Observable<any>
    {
        const url = (environment.production ? 'https://' : 'http://') + (useSecondary ? 'ipv4.jsonip.com/' : 'api.ipify.org/?format=json');
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get(url, { headers })
            .pipe(timeout(2000),
                catchError(error => useSecondary ? of(null) : this.getIP(true)));
    }

    private loginWithIp(model: UserLoginModel, ip: string = null): Observable<AuthTokenResponse> {
        return LocalCacheManager.uniqueId.pipe(switchMap(uniqueId => {

            console.log(`Unique device id: ${uniqueId}`);
            const body = {
                grantType: 'password',
                username: AuthApiService.getEncodedEmail(model.username),
                password: model.password,
                deviceId: uniqueId,
                phoneNumber: model.phoneNumber,
                ip: null,
            };
            if (ip != null) { body.ip = ip; }
            return this.apiRequest<any>(this.loginUrl, HttpMethod.Post, JSON.stringify(body)).pipe(
                map((response: any) => new AuthTokenResponse(response))
            );
        }));
    }

    loginWithTwoFactorCode(body: any): Observable<AuthTokenResponse>
    {
        return this.apiRequest<any>(this.twoFactorUrl, HttpMethod.Post, JSON.stringify(body)).pipe(
            map((response: any) => new AuthTokenResponse(response))
        );
    }

    loginWithRefreshToken(body: any): Observable<AuthTokenResponse>
    {
        return LocalCacheManager.uniqueId.pipe(switchMap(uniqueId => {
            console.log(`Unique device id (refresh): ${uniqueId}`);
            body.deviceId = uniqueId;

            return this.apiRequest<any>(this.loginUrl, HttpMethod.Post, JSON.stringify(body))
                .pipe(map((response: any) => new AuthTokenResponse(response)));
        }));
    }

    sendTwoFactorCodeViaEmail(body: any): Observable<AuthTokenResponse>
    {
        return this.apiRequest<any>(this.sendTwoFactorCodeViaEmailUrl, HttpMethod.Post, JSON.stringify(body)).pipe(
            map((response: any) => new AuthTokenResponse(response))
        );
    }

    needsPhoneNumber(username: string): Observable<boolean> {
        const body = {
            username: AuthApiService.getEncodedEmail(username),
        };
        return this.apiRequest<any>(this.needsPhoneNumberUrl, HttpMethod.Post, JSON.stringify(body))
            .pipe(map(response => response.needsPhoneNumber));
    }

    forgotPassword(username: string): Observable<Response>
    {
        return this.apiRequest(this.forgotPasswordUrl, HttpMethod.Post, JSON.stringify({  username }));
    }

    resetPassword(userId: string, password: string, token: string): Observable<Response>
    {
        return this.apiRequest(this.resetPasswordUrl, HttpMethod.Post, JSON.stringify({ userId, password, token }));
    }

    changePassword(oldPassword: string, newPassword: string): Observable<Response>
    {
        return this.apiRequest(this.changePasswordUrl, HttpMethod.Post, JSON.stringify({  oldPassword, newPassword }));
    }

    setUserPassword(email: string, password: string): Observable<Response>
    {
        return this.apiRequest(this.setUserPasswordUrl, HttpMethod.Post, JSON.stringify(
            {
                email: AuthApiService.getEncodedEmail(email),
                password
            }));
    }

    // =============================================================================================================================
    // User-related (CRUD)
    // =============================================================================================================================
    addUser(user: User, locationId: number, roles: string[]): Observable<User>
    {
        const updateObj = Object.assign({...user}, { locationId }, { roles });
        return this.apiRequest(this.addUserUrl, HttpMethod.Post, JSON.stringify(updateObj))
            .pipe(map(json => new User(json)));
    }

    updateUserProperties(user: User): Observable<User>
    {
        return this.apiRequest(this.updateUserPropertiesUrl, HttpMethod.Put, JSON.stringify(user));
    }

    updateUserRolesAndPermissions(userId: string, userPermissions: UserPermissions): Observable<User>
    {
        const updateObj = Object.assign({...userPermissions}, { userId });
        return this.apiRequest(this.updateUserRolesAndPermissionsUrl, HttpMethod.Put, JSON.stringify(updateObj));
    }

    deleteUser(userId: string): Observable<null>
    {
        return this.apiRequest(this.deleteUserUrl(userId), HttpMethod.Delete);
    }

    undeleteUser(email: string): Observable<null>
    {
        return this.apiRequest(this.undeleteUserUrl, HttpMethod.Post, JSON.stringify({ email }));
    }

    getUserPermissions(userId: string): Observable<UserPermissions>
    {
        return this.apiRequest<any>(this.getUserPermissionsUrl(userId), HttpMethod.Get)
            .pipe(map(json => new UserPermissions(json)));
    }

    getAllowedRolesAndPermissions(): Observable<Role[]>
    {
        return this.apiRequest<any>(this.getAllowedRolesAndPermissionsUrl, HttpMethod.Get)
            .pipe(map(roles => roles.rolesAndPermissions.map(json => new Role(json))));
    }

    acceptTermsOfUse(): Observable<null>
    {
        return this.apiRequest(this.acceptTermsOfUseUrl, HttpMethod.Post);
    }

    // =============================================================================================================================
    // URLs
    // =============================================================================================================================
    /* tslint:disable:member-ordering */

    private get baseUrl(): string { return this.baseApiUrl + 'auth'; }

    private get acceptTermsOfUseUrl(): string { return `${this.baseUrl}/acceptTermsOfUse`; }
    private get loginUrl(): string { return `${this.baseUrl}/token`; }
    private get changePasswordUrl(): string { return `${this.baseUrl}/changePassword`; }
    private get setUserPasswordUrl(): string { return `${this.baseUrl}/setUserPassword`; }
    private get forgotPasswordUrl(): string { return `${this.baseUrl}/forgotPassword`; }
    private get needsPhoneNumberUrl(): string { return `${this.baseUrl}/needsPhoneNumber`; }
    private get resetPasswordUrl(): string { return `${this.baseUrl}/resetPassword`; }
    private get twoFactorUrl(): string { return `${this.baseUrl}/twoFactor`; }
    private get sendTwoFactorCodeViaEmailUrl(): string { return `${this.baseUrl}/twoFactorEmail`; }

    private get addUserUrl(): string { return `${this.baseUrl}/addUser`; }
    private get updateUserPropertiesUrl(): string { return `${this.baseUrl}/updateUserProperties`; }
    private get updateUserRolesAndPermissionsUrl(): string { return `${this.baseUrl}/setPermissions`; }
    private deleteUserUrl(id: string): string { return `${this.baseUrl}/deleteUser/${id}`; }
    private get undeleteUserUrl(): string { return `${this.baseUrl}/undeleteUser`; }

    private getUserPermissionsUrl(id: string): string { return `${this.baseUrl}/getPermissions/${id}`; }
    private get getAllowedRolesAndPermissionsUrl(): string { return `${this.baseUrl}/getAllowedPermissions`; }
}
