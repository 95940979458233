import { Vendor } from './vendor.model';

export class VendorList
{
    public count: number;
    public vendors: Vendor[];

    constructor(json: any = null)
    {
        if (json)
        {
            this.vendors = json.vendors.map(info => new Vendor(info));
            this.count = json.count;
        }
    }
}
