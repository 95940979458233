import { JwtHelperService } from '@auth0/angular-jwt';


export class AuthTokenResponse
{
    public twoFactorToken: string;
    public token: string;
    public refreshToken: string;
    public expiration: Date;
    public refreshTokenExpiration: Date;

    constructor(authTokenResponse: AuthTokenResponse)
    {
        this.twoFactorToken = authTokenResponse.twoFactorToken;
        this.token = authTokenResponse.token;
        const decoded = this.token ? new JwtHelperService().decodeToken(this.token) : null;
        if (decoded != null) { Object.assign(this, decoded); }
        this.expiration = new Date(authTokenResponse.expiration);
        this.refreshToken = authTokenResponse.refreshToken;
        this.refreshTokenExpiration = new Date(authTokenResponse.refreshTokenExpiration);
    }

    public get isValid(): boolean
    {
        return this.hasToken && !this.isExpired;
    }

    private get isExpired(): boolean
    {
        return !this.expiration && this.expiration < new Date();
    }

    private get hasToken(): boolean
    {
        return this.token && this.token.length > 0;
    }
}
