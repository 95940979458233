import { Vendor } from '../vendor/vendor.model';
import { Location } from '../location/location.model';
import { Utils } from '../utils.service';
import { PurchaseOrderLineItem, PurchaseOrderLineItemMiscellaneous } from './purchase-order-line-item.model';

export enum PurchaseOrderTransactionActionType // Note: this MUST match the API
{
    Add,
    Update,
    Delete
}

export enum PurchaseOrderTransactionPartType // Note: this MUST match the API
{
    Converter,
    Custom,
    Category,
    Miscellaneous,
    NoNumber,
}

export class PurchaseOrderTransaction
{
    transactionId: number;
    purchaseOrderId: number;
    type: PurchaseOrderTransactionActionType;
    partType: PurchaseOrderTransactionPartType;
    itemId: number;  // Converter or Miscellaneous Part ID
    partNumber: string; // Display text
    lineItemId: number; // Will not be used if ActionType == Add

    pricePerItem: number;
    quantity: number;
    categoryHasCustomPrices: boolean; // Used only for Category converters
    byPound = false;
    levelPercentage: number;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }

    // constructor(purchaseOrderId: number,
    //             type: PurchaseOrderTransactionActionType,
    //             partType: PurchaseOrderTransactionPartType,
    //             itemId: number,
    //             lineItemId: number = null,
    //             pricePerItem: number = null,
    //             quantity: number = null,
    //             levelPercentage: number)
    // {
    //     this.purchaseOrderId = purchaseOrderId;
    //     this.type = type;
    //     this.partType = partType;
    //     this.itemId = itemId;
    //     this.lineItemId = lineItemId;
    //     this.pricePerItem = pricePerItem;
    //     this.quantity = quantity;
    //     this.levelPercentage = levelPercentage;
    // }
}
