import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, HttpMethod } from '../api.service';
import { UserLog } from './user-log.model';

@Injectable({
    providedIn: 'root'
})
export class UserLogApiService extends ApiService
{
    constructor(protected http: HttpClient)
    {
        super(http);
    }

    getLogs(): Observable<UserLog[]>
    {
        return this.apiRequest<any[]>(this.getAllLogsUrl, HttpMethod.Get)
            .pipe(map(result => result.map(json => new UserLog(json))));
    }

    getLogsForVendor(vendorId: number): Observable<UserLog[]>
    {
        return this.apiRequest<any[]>(this.getLogsForVendorUrl(vendorId), HttpMethod.Get)
            .pipe(map(result => result.map(json => new UserLog(json))));
    }

    addUserLog(userLog: UserLog): Observable<UserLog>
    {
        return this.apiRequest(this.addUserLogUrl, HttpMethod.Post, JSON.stringify(userLog))
            .pipe(map(json => new UserLog(json)));
    }

    deleteUserLog(id: number): Observable<boolean>
    {
        return this.apiRequest<any>(this.deleteUserLogUrl(id), HttpMethod.Delete).pipe(map(() => true));
    }

    // =============================================================================================================================
    // URLs
    // =============================================================================================================================
    /* tslint:disable:member-ordering */
    // tslint:disable:max-line-length
    private get baseUrl(): string { return this.baseApiUrl + 'userLogs'; }
    private get getAllLogsUrl(): string { return this.baseUrl; }
    private getLogsForVendorUrl(vendorId: number): string { return `${this.baseUrl}/vendor/${vendorId}`; }
    private get addUserLogUrl(): string { return this.baseUrl; }
    private deleteUserLogUrl(id: number): string { return `${this.baseUrl}?logId=${id}`; }
}
