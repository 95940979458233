<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="wrapper" [class.no-location]="!currentUser.locationId" (click)="loadMenuItems(); menu.toggle($event)">
    <div *ngIf="!deviceManager.isMobile"><i class="fa fa-map-marker-alt"></i></div>
    <div class="location-name-area">
        <div *ngIf="deviceManager.isMobile" class="location-label">Location: </div>
        <div *ngIf="currentUser.locationId != null" class="location-text">{{currentUser.locationName}}</div>
    </div>
</div>

<p-menu #menu [popup]="true" [model]="locationItems"></p-menu>
