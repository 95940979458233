import * as moment from 'moment';
import { Utils } from '../utils.service';
import { MetalPrices } from '../pricing/metal-prices.model';
import { ProcessorTerms } from '../pricing/processor-terms.model';
import { Hedge } from '../pricing/hedge.model';
import { User, UserNotificationMethod } from '../user/user.model';
import { Contact } from './contact.model';

export enum VendorLicenseState
{
    Valid,
    Expired,
    Missing,
}

export class Vendor
{
    public static ASSAY_VENDOR_LOCATION_ID = -2;
    public static LICENSE_TYPE_SELLERS_PERMIT = 'Seller\'s Permit';
    public static LICENSE_TYPE_SALES_AND_USE_PERMIT = 'Sales and Use Permit';
    // tslint:disable-next-line:max-line-length
    public static CANNOT_UPDATE_VENDOR_MESSAGE = 'The selected vendor does not have all required information, and you do not have permission to update the vendor. Please contact the administrator for assistance.';

    public numberOfPurchaseOrders: number;
    public purchaseOrderIds: number[] = [];
    public id = 0;
    public isInactive = false;
    public name: string;
    public address: string;
    public city: string;
    public latitude?: number;
    public logoImageUrl: string;
    public licenseNumber: string;
    public licenseType: string;
    public licenseImageUrls: string[] = [];
    public licenseExpiration: Date;
    public licenseLastUpdated: Date;
    public locationName: string;
    public longitude?: number;
    public state: string;
    public zip: string;
    public parentVendor: Vendor;
    public parentVendorId: number;
    public parentVendorName: string;
    public isAssayVendor: boolean;
    public memo1: string;
    public memo2: string;
    public primaryContact: string;
    public contactTitle: string;
    public mainPhone: string;
    public fax: string;
    public alternatePhone: string;
    public secondaryContact: string;
    public locationId: number;
    public email: string;
    public metalPrices: MetalPrices[];
    public numberOfLogs: number;
    public processorTerms: ProcessorTerms[];
    public hedges: Hedge[];
    public buyers: User[];
    public contacts: Contact[] = [];
    public pricingNotificationMethod = UserNotificationMethod.None;
    public contractNotificationMethod = UserNotificationMethod.None;
    public statusUpdateNotificationMethod = UserNotificationMethod.None;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.licenseExpiration != null) this.licenseExpiration = Utils.convertApiDateStringToDate(json.licenseExpiration);
            if (json.licenseLastUpdated != null) this.licenseLastUpdated = Utils.convertApiDateStringToDate(json.licenseLastUpdated);
            if (json.metalPrices != null) this.metalPrices = json.metalPrices.map(item => new MetalPrices(item));
            if (json.parentVendor != null) this.parentVendor = new Vendor(json.parentVendor);
            if (json.processorTerms != null) this.processorTerms = json.processorTerms.map(item => new ProcessorTerms(item));
            if (json.hedges != null) this.hedges = json.hedges.map(item => new Hedge(item));
            if (json.buyers != null) this.buyers = json.buyers.map(item => new User(item));
            if (json.contacts != null) this.contacts = json.contacts.map(item => new Contact(item));
            if (this.licenseImageUrls == null) this.licenseImageUrls = [];
        }
    }

    clear(): void
    {
        this.name = '';
        this.address = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.email = '';
    }

    get cityStateZip(): string
    {
        let cityStateZip = this.city || '';
        cityStateZip += (cityStateZip.length === 0 ? '' : ', ') + (this.state ? this.state : '');
        cityStateZip += (cityStateZip.length === 0 ? '' : ' ') + (this.zip ? this.zip : '');
        return cityStateZip;
    }

    // Simply for search
    get fullAddress(): string { return `${this.address || ''} ${this.cityStateZip}`; }

    get fullAddressHTML(): string
    {
        return '<div class="address">' +
            `<div class="street-address">${this.address || ''}</div>` +
            `<div class="city-state-zip">${this.cityStateZip}</div>` +
            '</div>';
    }

    get isLicenseExpirationRequired(): boolean
    {
        return Vendor.isLicenseExpirationRequiredForType(this.licenseType);
    }

    // tslint:disable-next-line:member-ordering
    static isLicenseExpirationRequiredForType(licenseType: string): boolean
    {
        return licenseType !== Vendor.LICENSE_TYPE_SALES_AND_USE_PERMIT &&
            licenseType !== Vendor.LICENSE_TYPE_SELLERS_PERMIT;
    }

    get isLicenseExpirationValid(): boolean {
        if (this.licenseExpiration == null) return !this.isLicenseExpirationRequired;

        const m = moment(this.licenseExpiration);
        const tomorrow = moment().startOf('day').add(1, 'day');
        return m.isValid() && m >= tomorrow && m.year() <= 2050;
    }

    get licenseState(): VendorLicenseState
    {
        return this.isLicenseExpirationValid ? VendorLicenseState.Valid :
            this.licenseExpiration == null ? VendorLicenseState.Missing : VendorLicenseState.Expired;
    }

    get hasCompleteData(): boolean {
        return (this.name != null && this.name.length > 0) &&
            (this.address != null && this.address.length > 0) &&
            (this.city != null && this.city.length > 0) &&
            (this.state != null && this.state.length > 0) &&
            (this.zip != null && this.zip.length > 0) &&
            this.hasCompleteLicenseData;
    }

    get hasCompleteLicenseData(): boolean {
        return (this.licenseNumber != null && this.licenseNumber.length > 0) && this.isLicenseExpirationValid;
    }
}
