import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as cloneDeep from 'lodash/cloneDeep';

import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';

import { WaitingIndicatorComponent } from '../../waiting-indicator/waiting-indicator.component';
import { PurchaseOrderManagerService } from '../../../services/purchase-order/purchase-order-manager.service';
import { DeviceManagerService } from '../../../core/services/device-manager.service';
import { PurchaseOrderLineItem, PurchaseOrderLineItemMiscellaneous } from '../../../services/purchase-order/purchase-order-line-item.model';
import { PurchaseOrderTransactionPartType } from '../../../services/purchase-order/purchase-order-transaction';
import { PricingType } from '../../../services/miscellaneous-part/miscellaneous-part.model';
import { Vendor } from '../../../services/vendor/vendor.model';
import { VendorManagerService } from '../../../services/vendor/vendor-manager.service';
import { AuthManagerService } from '../../../services/auth/auth-manager.service';
import { PurchaseOrderPDFComponent, SignaturePageType } from '../purchase-order-pdf/purchase-order-pdf.component';
import { LocalCacheManager } from '../../../managers/local-cache.manager';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { ThemeManager } from '../../../managers/theme.manager';
import { AssignedImageResult } from '../../../services/converter/assigned-image-result.model';
import { ImageApiService } from '../../../shared-api/images/image-api.service';

@Component({
    selector: 'ls-purchase-order',
    templateUrl: './purchase-order.component.html',
    styleUrls: [ './purchase-order.component.less' ],
    animations: WaitingIndicatorComponent.animations_no_fade,
})
export class PurchaseOrderComponent implements OnInit, OnDestroy
{
    static isDisplayed = false;
    static ONE_LINE_TABLE_HEIGHT = 105;
    static TWO_LINE_TABLE_HEIGHT = 142;

    @HostBinding('class') get classes(): string { return ThemeManager.theme; }
    @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;

    @ViewChild('pdf', { static: false }) pdf: PurchaseOrderPDFComponent;
    @Output() hide = new EventEmitter();

    busy = false;
    error: string;
    convertersDisplayed = false;
    // customDisplayed = false;
    editedItemCopy: PurchaseOrderLineItem | PurchaseOrderLineItemMiscellaneous;
    gettingSignature = false;
    imageHoverIndex: number;
    headerDisplayed = true;
    levelOptions = PurchaseOrderLineItem.levelPercentageDropdownOptions;
    maxPurchaseDate = new Date();
    miscDisplayed = false;
    // categoryDisplayed = false;
    selectedVendorId: number;
    vendors: Vendor[];

    converterColumns = [
        { field: 'converterPartNumber', header: 'Part #', width: '10%' },
        { field: 'converterGroupName', header: 'Manufacturer', width: '10%' },
        { field: 'quantity', header: 'Quantity', width: '10%', style: { 'text-align': 'center' } },
        { field: 'levelPercentageText', header: 'Metal Quantity', width: '10%', style: { 'text-align': 'center' } },
        { field: 'pricePerItem', header: 'Price / item', width: '10%', style: { 'text-align': 'center' } },
        { field: 'totalPrice', header: 'Total Price', width: '10%', style: { 'text-align': 'center' } },
        { field: 'editColumn', header: '', width: '10%', style: { 'text-align': 'center' }, sortDisabled: true },
    ];

    customColumns = [
        { field: 'converterGroupName', header: 'Manufacturer', width: '10%' },
        { field: 'quantity', header: 'Quantity', width: '10%', style: { 'text-align': 'center' } },
        { field: 'pricePerItem', header: 'Price / item', width: '10%', style: { 'text-align': 'center' } },
        { field: 'totalPrice', header: 'Total Price', width: '10%', style: { 'text-align': 'center' } },
        { field: 'editColumn', header: '', width: '10%', style: { 'text-align': 'center' }, sortDisabled: true },
    ];

    categoryPartColumns = [
        { field: 'converterGroupName', header: 'Manufacturer', width: '10%' },
        { field: 'quantity', header: 'Quantity', width: '10%', style: { 'text-align': 'center' } },
        { field: 'levelPercentageText', header: 'Metal Quantity', width: '10%', style: { 'text-align': 'center' } },
        { field: 'pricePerItem', header: 'Price / item', width: '10%', style: { 'text-align': 'center' } },
        { field: 'totalPrice', header: 'Total Price', width: '10%', style: { 'text-align': 'center' } },
        { field: 'editColumn', header: '', width: '10%', style: { 'text-align': 'center' }, sortDisabled: true },
    ];

    miscColumns = [
        { field: 'pricingType', header: 'Type', width: '15%' },
        { field: 'description', header: 'Description', width: '20%' },
        { field: 'quantity', header: 'Quantity', width: '10%', style: { 'text-align': 'center' } },
        { field: 'pricePerItem', header: 'Price / item', width: '10%', style: { 'text-align': 'center' } },
        { field: 'totalPrice', header: 'Total Price', width: '10%', style: { 'text-align': 'center' } },
        { field: 'editColumn', header: '', width: '10%', style: { 'text-align': 'center' }, sortDisabled: true },
    ];

    signaturePageMenuItems = [
        { label: 'California', type: SignaturePageType.California, command: null },
        { label: 'Illinois', type: SignaturePageType.Illinois, command: null },
        { label: 'Texas', type: SignaturePageType.Texas, command: null },
        { label: 'West Virginia', type: SignaturePageType.WestVirginia, command: null },
        { label: 'Default', type: SignaturePageType.Default, command: null },
    ];

    allPrintMenuItems = [
        { label: 'Send Summary to Vendor', command: null, items: null, emailToVendor: true, showDetails: false, wordDoc: false },
        { label: 'Send Detailed to Vendor', command: null, items: null, emailToVendor: true, showDetails: true, wordDoc: false },
        { label: 'Create Summary PDF', command: null, items: null, emailToVendor: false, showDetails: false, wordDoc: false },
        { label: 'Create Detailed PDF', command: null, items: null, emailToVendor: false, showDetails: true, wordDoc: false },
        { label: 'Get Detailed Word Document', command: null, items: null, emailToVendor: false, showDetails: true, wordDoc: true },
    ];
    printMenuItems: any[];

    PricingType = PricingType;
    PurchaseOrderTransactionPartType = PurchaseOrderTransactionPartType;
    LocalCacheManager = LocalCacheManager;
    ThemeManager = ThemeManager;
    ImageApiService = ImageApiService;

    private emailToVendorAfterSignature: boolean;
    private originalPurchaseDate: Date;
    private showDetailsAfterSignature: boolean;
    private generateWordDocAfterSignature: boolean;

    constructor(private router: Router,
                public authManager: AuthManagerService,
                private confirmationService: ConfirmationService,
                public deviceManager: DeviceManagerService,
                private messageService: MessageService,
                public poManager: PurchaseOrderManagerService,
                private vendorManager: VendorManagerService)
    { }

    ngOnInit(): void
    {
        this.authManager.currentUserChanged.subscribe(() => PurchaseOrderComponent.isDisplayed = false);

        const numSectionsDisplayed = this.convertersDisplayed ? 1 : 0 + (this.miscDisplayed ? 1 : 0);
        const totalLineItems = this.poManager.current.lineItemsConverter.length + this.poManager.current.lineItemsCustom.length +
            this.poManager.current.lineItemsNoNumber.length + this.poManager.current.lineItemsCategory.length +
            this.poManager.current.lineItemsMiscellaneous.length;
        this.convertersDisplayed = this.miscDisplayed = this.deviceManager.isDesktop || numSectionsDisplayed === 1 || totalLineItems < 5;
        PurchaseOrderComponent.isDisplayed = true;

        // Add signature page options to each Print option
        this.setupPrintMenu();

        if (!this.poManager.current.isLocked) this.loadVendors();
        this.originalPurchaseDate = this.poManager.current.purchaseDate;
    }

    ngOnDestroy(): void {
        PurchaseOrderComponent.isDisplayed = false;
    }

    //region PO-level operations
    changeVendor(event: any): void
    {
        if (this.selectedVendorId === this.poManager.current.vendor.id) return;

        this.busy = true;
        this.poManager.changeVendor(event.value).pipe(finalize(() =>
        {
            this.busy = false;
        })).subscribe();
    }

    setPurchaseDate(): void
    {
        this.confirmationService.confirm({
            message: 'This PO has been locked. Are you sure you want to update the date for a locked PO?',
            header: 'Update Locked PO?',
            icon: 'pi pi-exclamation-triangle',
            accept: () =>
            {
                this.setPurchaseDateInternal();
            },
            reject: () =>
            {
                this.poManager.current.purchaseDate = this.originalPurchaseDate;
            }
        });
    }

    private setPurchaseDateInternal(): void
    {
        this.originalPurchaseDate = this.poManager.current.purchaseDate;
        this.busy = true;
        this.poManager.setPurchaseDate(this.poManager.current.purchaseDate).pipe(finalize(() => this.busy = false)).subscribe();
    }

    deletePO(): void
    {
        this.confirmationService.confirm({
            message: 'This will permanently delete the PO. Do you want to continue?',
            header: 'Permanently Delete?',
            icon: 'pi pi-exclamation-triangle',
            accept: () =>
            {
                this.busy = true;
                this.poManager.deletePO(this.poManager.current.id).pipe(finalize(() => this.busy = false))
                    .subscribe(() => { this.closePO(); }, error =>
                    {
                        this.error = ErrorHandlerService.handleError(this, error, 'deletePO()', false, false);
                    });

            }
        });
    }

    addParts(): void
    {
        this.closeView();
        this.router.navigate([ '/converters/part-number' ]);
    }

    outputPDF(emailToVendor: boolean, showDetails: boolean, generateWordDoc: boolean, signaturePageType: SignaturePageType): void
    {
        // this.pdf.initializeBrowserWindow();
        this.emailToVendorAfterSignature = emailToVendor;
        this.showDetailsAfterSignature = showDetails;
        this.generateWordDocAfterSignature = generateWordDoc;
        if (this.poManager.current.isLocked)
        {
            this.gettingSignature = true;
            this.pdf.captureSignature(signaturePageType);
            return;
        }

        this.confirmationService.confirm({
            message: 'This will finalize the PO and lock it from further changes. Do you want to continue?',
            header: 'Confirmation',
            icon: 'fa fa-question-circle',
            accept: () =>
            {
                this.gettingSignature = true;
                this.pdf.captureSignature(signaturePageType);
            }
        });
    }

    updatePrices(): void
    {
        this.busy = true;
        this.poManager.updatePrices().pipe(finalize(() => this.busy = false)).subscribe();
    }

    lock(): void
    {
        this.busy = true;
        this.poManager.lock().pipe(finalize(() => this.busy = false)).subscribe();
    }

    refresh(): void
    {
        this.busy = true;
        this.poManager.retryTransactions().pipe(finalize(() => this.busy = false), switchMap(result =>
        {
            if (!result) return of(false);
            return this.poManager.getPurchaseOrder(this.poManager.current.id);
        })).subscribe();
    }

    unlock(): void
    {
        this.confirmationService.confirm({
            message: 'Are you sure you want to unlock this Purchase Order?',
            header: 'Unlock?',
            icon: 'pi pi-exclamation-triangle',
            accept: () =>
            {
                this.poManager.unlock().subscribe(() => this.setupPrintMenu());
            }
        });
    }

    // private captureSignature(emailToVendor: boolean, showDetails: boolean): void
    // {
    //     if (!this.pdf.containsCompliancePage())
    //         return this.pdf.create(emailToVendor, showDetails, false);
    //
    //     // this.pdf.create(false, showDetails); // Create a PDF just for user's review
    //     this.emailToVendorAfterSignature = emailToVendor;
    //     this.showDetailsAfterSignature = showDetails;
    //     this.gettingSignature = true;
    // }
    //
    onSignatureCompleted(complete: boolean): void
    {
        this.gettingSignature = false;
        if (!complete) return;

        // If it is already locked we can simply generate it
        if (this.poManager.current.isLocked)
        {
            if (this.generateWordDocAfterSignature)
                this.generateWordDoc();
            else
                this.pdf.create(this.emailToVendorAfterSignature, this.showDetailsAfterSignature);
            return;
        }

        // Not locked - lock it then proceed
        this.busy = true;
        this.poManager.updatePaymentType().subscribe(() =>
        {
            this.poManager.lock().pipe(finalize(() => this.busy = false)).subscribe(() =>
                {
                    if (this.generateWordDocAfterSignature)
                    {
                        this.generateWordDoc();
                    }
                    else
                    {
                        this.pdf.create(this.emailToVendorAfterSignature, this.showDetailsAfterSignature);
                    }
                },
                error =>
                {
                    this.error = ErrorHandlerService.handleError(this, error, 'outputPDF()', false, false);
                });
        },
        error =>
        {
            this.error = ErrorHandlerService.handleError(this, error, 'outputPDF()', false, false);
        });
    }

    private generateWordDoc(): void
    {
        this.poManager.outputPurchaseOrderAsWordDoc(this.poManager.current.id).subscribe(result => {
            const blob = new Blob([result], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${this.poManager.current.number}.docx`;
            link.click();
            this.setupPrintMenu();
        });
    }

    onPdfGenerated(complete: boolean): void
    {
        this.busy = false;
        this.setupPrintMenu();
    }
    //endregion

    //region Row editing
    onRowEditInit(lineItem: PurchaseOrderLineItem | PurchaseOrderLineItemMiscellaneous): void
    {
        this.editedItemCopy = lineItem instanceof PurchaseOrderLineItem
            ? new PurchaseOrderLineItem(cloneDeep(lineItem))
            : new PurchaseOrderLineItemMiscellaneous(cloneDeep(lineItem));
    }

    onRowEditSave(arr: PurchaseOrderLineItem[] | PurchaseOrderLineItemMiscellaneous[],
                  lineItem: PurchaseOrderLineItem | PurchaseOrderLineItemMiscellaneous): void
    {
        if (lineItem.quantity === this.editedItemCopy.quantity &&
            lineItem instanceof PurchaseOrderLineItem &&
            lineItem.levelPercentage === (this.editedItemCopy as PurchaseOrderLineItem).levelPercentage)
        {
            return;
        }

        this.busy = true;
        if (lineItem instanceof PurchaseOrderLineItem)
        {
            this.poManager.updateConverterDirect(lineItem).pipe(finalize(() => this.busy = false)).subscribe(success =>
            {
                if (success)
                {
                    this.poManager.current.updateQuantitiesAndPrices();
                    this.editedItemCopy = null;
                    return;
                }

                this.onRowEditCancel(arr);
            });
            return;
        }

        this.poManager.updateMiscPartDirect(lineItem).pipe(finalize(() => this.busy = false)).subscribe(success =>
        {
            if (success)
            {
                this.poManager.current.updateQuantitiesAndPrices();
                this.editedItemCopy = null;
                return;
            }

            this.onRowEditCancel(arr);
        });
    }

    onRowEditCancel(arr: PurchaseOrderLineItem[] | PurchaseOrderLineItemMiscellaneous[]): void
    {
        const index = arr.findIndex(li => li.id === this.editedItemCopy.id);
        arr[index] = this.editedItemCopy;
        if (this.poManager.current.lineItemsAllConverters === arr) {
            this.poManager.current.lineItemsAllConverters = [ ...arr ];
            const copy = this.editedItemCopy as PurchaseOrderLineItem;
            let indexInOriginalList = this.poManager.current.lineItemsConverter
                .findIndex(li => li.id === this.editedItemCopy.id && li.type === copy.type);
            if (indexInOriginalList !== -1) this.poManager.current.lineItemsConverter[indexInOriginalList] = copy;
            indexInOriginalList = this.poManager.current.lineItemsNoNumber
                .findIndex(li => li.id === this.editedItemCopy.id && li.type === copy.type);
            if (indexInOriginalList !== -1) this.poManager.current.lineItemsNoNumber[indexInOriginalList] = copy;
            indexInOriginalList = this.poManager.current.lineItemsCategory
                .findIndex(li => li.id === this.editedItemCopy.id && li.type === copy.type);
            if (indexInOriginalList !== -1) this.poManager.current.lineItemsCategory[indexInOriginalList] = copy;
            indexInOriginalList = this.poManager.current.lineItemsCustom
                .findIndex(li => li.id === this.editedItemCopy.id && li.type === copy.type);
            if (indexInOriginalList !== -1) this.poManager.current.lineItemsCustom[indexInOriginalList] = copy;
        }
        if (this.poManager.current.lineItemsMiscellaneous === arr) this.poManager.current.lineItemsMiscellaneous = [ ...arr ];
        this.editedItemCopy = null;
    }

    onDeleteLineItem(arr: PurchaseOrderLineItem[] | PurchaseOrderLineItemMiscellaneous[],
                     lineItem: PurchaseOrderLineItem | PurchaseOrderLineItemMiscellaneous): void
    {
        const partType = this.getPartTypeForList(arr, lineItem);
        this.confirmationService.confirm({
            message: 'This line will be deleted permanently. Do you wish to continue?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () =>
            {
                this.busy = true;
                this.poManager.deleteConverterOrPartDirect(partType, lineItem).pipe(finalize(() => this.busy = false)).subscribe(result =>
                {
                    if (result) {
                        arr.splice(arr.findIndex(li => li.id === lineItem.id), 1);
                        this.poManager.current.updateQuantitiesAndPrices();
                    }
                });
            }
        });
    }

    uploadImageSelected(event: any): void
    {
        const fileToUpload = event.files[0];
        this.busy = true;
        this.poManager.uploadImage(this.poManager.current.id, fileToUpload).pipe(finalize(() => { this.busy = false; }))
            .subscribe((result: AssignedImageResult) =>
            {
                this.poManager.current.imageUrls.push(result.url);
                this.messageService.add({
                    severity: 'success',
                    summary: `Purchase Order File`,
                    detail: `The Purchase Order File has been uploaded.`
                });
            }, (error) =>
            {
                const msg = ErrorHandlerService.handleError(this, error, 'uploadImageSelected()', false, false);
                if (msg.length > 0) this.messageService.add({ severity: 'error', summary: 'PO Image Error', detail: msg });
            });
        this.fileUpload.clear();
    }

    onImageClicked(index: number): void
    {
        window.open(this.poManager.current.imageUrls[index], '_blank');
    }

    onImageRemoved(index: number): void
    {
        this.confirmationService.confirm({
            message: 'Are you sure you want to remove the selected image?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () =>
            {
                this.busy = true;
                const url = this.poManager.current.imageUrls[index];
                this.poManager.removeImage(this.poManager.current.id, url)
                    .pipe(finalize(() => { this.busy = false; }))
                    .subscribe(() =>
                    {
                        this.poManager.current.imageUrls = this.poManager.current.imageUrls.filter(i => i !== url);
                        this.messageService.add({
                            severity: 'success',
                            summary: `Purchase Order File`,
                            detail: `The Purchase Order File has been removed.`
                        });
                    }, (error) =>
                    {
                        const msg = ErrorHandlerService.handleError(this, error, 'onImageRemoved()', false, false);
                        if (msg.length > 0) this.messageService.add({ severity: 'error', summary: 'PO Image Error', detail: msg });
                    });
            }
        });
    }
    //endregion

    //region Misc.
    loadVendors(): void
    {
        if (this.vendors != null)
        {
            setTimeout(() => this.selectedVendorId = this.poManager.current.vendor.id, 100);
            return;
        }

        this.vendorManager.getAllVendors(this.poManager.current.locationId, true).subscribe(vendors => {
            this.vendors = vendors.vendors;
            setTimeout(() => this.selectedVendorId = this.poManager.current.vendor.id, 100);
        });
    }

    private setupPrintMenu(): void
    {
        const po = this.poManager.current;
        this.printMenuItems = po.vendor.email != null && po.vendor.email.length > 0 ? this.allPrintMenuItems
            : this.allPrintMenuItems.slice(2);
        if (!po.isLocked || this.requiresSignaturePages) this.printMenuItems = this.printMenuItems.filter(m => !m.wordDoc);
        if (this.requiresSignaturePages)
        {
            this.printMenuItems.forEach(printItem =>
            {
                const signatureItems = cloneDeep(this.signaturePageMenuItems);
                signatureItems.forEach(item => item.command = () =>
                    this.outputPDF(printItem.emailToVendor, printItem.showDetails, printItem.wordDoc, item.type));
                printItem.items = signatureItems;
                printItem.command = null;
            });
        }
        else
        {
            this.printMenuItems.forEach(printItem =>
            {
                printItem.command = () => this.outputPDF(printItem.emailToVendor, printItem.showDetails, printItem.wordDoc, null);
                printItem.items = null;
            });
        }
    }

    get requiresSignaturePages(): boolean
    {
        const po = this.poManager.current;
        return po.signaturePageUrl == null && po.location != null &&
            !this.authManager.currentUser.isAssayUser && !this.authManager.currentUser.exemptFromVendorLicenseRequirements;
    }

    minTableHeight(list: PurchaseOrderLineItem[] | PurchaseOrderLineItemMiscellaneous[]): number
    {
        return list.length > 1 ? PurchaseOrderComponent.TWO_LINE_TABLE_HEIGHT : PurchaseOrderComponent.ONE_LINE_TABLE_HEIGHT;
    }

    closePO(): void
    {
        this.poManager.retryTransactions().subscribe(result =>
        {
            if (result)
            {
                this.closeView();
                this.poManager.setCurrentPO(null).subscribe();
                return;
            }

            this.confirmationService.confirm({
                message: 'You have changes to the Purchase Order that have not been uploaded.\n\nIf you close this Purchase Order, you will lose those changes permanently.\n\nAre you sure you want to continue to close the Purchase Order?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () =>
                {
                    this.poManager.clearTransactionQueue();
                    this.closeView();
                    this.poManager.setCurrentPO(null);
                }
            });
        });
    }

    closeView(): void
    {
        PurchaseOrderComponent.isDisplayed = false;
        this.hide.emit();
    }

    onErrorDismissed(): void
    {
        this.error = null;
    }

    private getPartTypeForList(arr: PurchaseOrderLineItem[] | PurchaseOrderLineItemMiscellaneous[],
                               lineItem: PurchaseOrderLineItem | PurchaseOrderLineItemMiscellaneous): PurchaseOrderTransactionPartType
    {
        return arr === this.poManager.current.lineItemsAllConverters ? (lineItem as PurchaseOrderLineItem).type
            : PurchaseOrderTransactionPartType.Miscellaneous;
    }
    //endregion
}
