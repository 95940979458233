export class SasTokenModel
{
    public token: string;

    constructor(json: object = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}
