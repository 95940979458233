<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<form class="po-pdf">
    <div class="header">
        <div class="header-left">
            <div>{{headerLeftText1}}</div>
            <div>{{headerLeftText2}}</div>
            <div>{{headerLeftText3}}</div>
        </div>
        <div class="header-center">
            <div class="center-logo"><img src="/assets/images/west_virginia_state_police_logo.png" alt="logo"/></div>
            <div class="center-title">{{headerCenterTextTitle}}</div>
            <div class="center-info">{{headerCenterTextInfo}}</div>
        </div>
        <div class="header-right">
            <div class="header-right-content">{{headerRightText}}</div>
        </div>
    </div>

    <div class="intro">
        <div>{{introRegularText1}}</div>
        <div>{{introRegularText2}}</div>
        <div>{{introRegularText3}}</div>
        <div>{{introRegularText4}}</div>
    </div>

    <div class="po-info">
        <div>1.</div>
        <div class="po-info-section">
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(fullNameOfSellerText)}}</span><input type="text" pInputText [(ngModel)]="fullNameOfSeller" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(homeAddressText)}}</span><input type="text" pInputText [(ngModel)]="homeAddress" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
            <div class="multiple-fields">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(sellersHomeCountyText)}}</span><input type="text" pInputText [(ngModel)]="sellersHomeCounty" [ngModelOptions]="{standalone: true}"/>
                </div>
                <div class="p-inputgroup zip">
                    <span>{{removeUnderscores(sellersHomeZipText)}}</span><input type="text" pInputText [(ngModel)]="sellersHomeZip" [ngModelOptions]="{standalone: true}"/>
                </div>
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(sellersHomePhoneNumberText)}}</span><input type="text" pInputText [(ngModel)]="sellersHomePhoneNumber" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
        </div>
    </div>

    <div class="po-info">
        <div>2.</div>
        <div class="po-info-section">
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(sellersBusinessAddressText)}}</span><input type="text" pInputText [(ngModel)]="sellersBusinessAddress" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
            <div class="multiple-fields">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(sellersBusinessCountyText)}}</span><input type="text" pInputText [(ngModel)]="sellersBusinessCounty" [ngModelOptions]="{standalone: true}"/>
                </div>
                <div class="p-inputgroup zip">
                    <span>{{removeUnderscores(sellersBusinessZipText)}}</span><input type="text" pInputText [(ngModel)]="sellersBusinessZip" [ngModelOptions]="{standalone: true}"/>
                </div>
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(sellersBusinessPhoneNumberText)}}</span><input type="text" pInputText [(ngModel)]="sellersBusinessPhoneNumber" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
        </div>
    </div>

    <div class="po-info">
        <div style="padding-top: 0">3.</div>
        <div class="po-info-section">
            <div>{{descriptionForTransportText}}</div>
            <div class="multiple-fields">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(makeText)}}</span><input type="text" pInputText [(ngModel)]="make" [ngModelOptions]="{standalone: true}"/>
                </div>
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(modelText)}}</span><input type="text" pInputText [(ngModel)]="model" [ngModelOptions]="{standalone: true}"/>
                </div>
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(licenseNumberText)}}</span><input type="text" pInputText [(ngModel)]="licenseNumber" [ngModelOptions]="{standalone: true}"/>
                </div>
                <div class="p-inputgroup state-of-issue">
                    <span>{{removeUnderscores(stateOfIssueText)}}</span><input type="text" pInputText [(ngModel)]="stateOfIssue" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
            <div class="field-group">
                <div class="p-inputgroup color">
                    <span>{{removeUnderscores(colorText)}}</span><input type="text" pInputText [(ngModel)]="color" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
        </div>
    </div>

    <div class="po-info">
        <div>4.</div>
        <div class="po-info-section">
            <div class="multiple-fields">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(dateOfTransactionText)}}</span><input type="text" pInputText [(ngModel)]="dateOfTransaction" [ngModelOptions]="{standalone: true}" [disabled]="true"/>
                </div>
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(timeOfTransactionText)}}</span><input type="text" pInputText [(ngModel)]="timeOfTransaction" [ngModelOptions]="{standalone: true}" [disabled]="true"/>
                </div>
            </div>
        </div>
    </div>

    <div class="po-info">
        <div style="padding-top: 0">5.</div>
        <div class="po-info-section">
            <div>{{descriptionOfKindText}}</div>
            <div *ngFor="let description of descriptions; let index = index;" class="multiple-fields">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(purchasedText)}}</span><input type="text" pInputText [(ngModel)]="description.purchased" [ngModelOptions]="{standalone: true}"/>
                </div>
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(characterText)}}</span><input type="text" pInputText [(ngModel)]="description.character" [ngModelOptions]="{standalone: true}"/>
                </div>
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(weightText)}}</span><input type="text" pInputText [(ngModel)]="description.weight" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
        </div>
    </div>

    <div class="po-info">
        <div style="padding-top: 0">6.</div>
        <div class="po-info-section">
            <div class="multiple-fields">
                <div style="flex-shrink: 0">{{wasMetalText}}</div>
                <div class="p-inputgroup">
                    <p-checkbox [(ngModel)]="purchasedOption" [label]="purchasedOptionText" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
                </div>
                <div class="p-inputgroup">
                    <p-checkbox [(ngModel)]="collateralOption" [label]="collateralOptionText" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
                </div>
                <div class="p-inputgroup">
                    <p-checkbox [(ngModel)]="consignmentOption" [label]="consignmentText" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div class="po-info">
        <div style="padding-top: 0">7.</div>
        <div class="po-info-section" [innerHTML]="section7Text"></div>
    </div>

    <div class="po-info">
        <div>8.</div>
        <div class="po-info-section">
            <div class="multi-signature-line tall signature">
                <div class="text">{{removeUnderscores(purchaserSignatureText)}}</div>
                <div class="signature-auto-expand"><ls-capture-signature #purchaserSignature></ls-capture-signature></div>
            </div>
        </div>
    </div>

    <div class="po-info">
        <div style="padding-top: 0">9.</div>
        <div class="po-info-section" [innerHTML]="section9Text"></div>
    </div>

    <div class="po-info">
        <div>10.</div>
        <div class="po-info-section">
            <div class="multi-signature-line tall signature">
                <div class="text">{{removeUnderscores(sellerSignatureText)}}</div>
                <div class="signature-auto-expand"><ls-capture-signature #sellerSignature></ls-capture-signature></div>
            </div>
        </div>
    </div>

    <div class="buttons">
        <div class="buttons-left">
            <button class="btn btn-secondary" (click)="clearSignatures()">Clear Signatures</button>
        </div>
        <div class="buttons-right">
            <button class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            <button class="btn btn-primary" [disabled]="sellerSignature.isEmpty || purchaserSignature.isEmpty ||
                fullNameOfSeller.length === 0 || homeAddress.length === 0 || sellersHomeCounty.length === 0 || sellersHomeZip.length === 0 || sellersHomePhoneNumber.length === 0"
                    (click)="onSave()">
                Confirm
            </button>
<!--            sellersBusinessAddress.length === 0 || sellersBusinessCounty.length === 0 || sellersBusinessZip.length === 0 || sellersBusinessPhoneNumber.length === 0"-->
        </div>
    </div>
</form>
