/* tslint:disable:max-line-length */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './common/guards/auth-guard.service';
// import { StartComponent } from './start/start.component';

const appRoutes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: 'dashboard', loadChildren: () => import('./sections/dashboard/dashboard.module').then(m => m.DashboardModule) },
    { path: 'converters', loadChildren: () => import('./sections/converters/converters.module').then(m => m.ConvertersModule) },
    { path: 'non-ferrous', loadChildren: () => import('./sections/non-ferrous/non-ferrous.module').then(m => m.NonFerrousModule) },
    { path: 'purchaseOrders', loadChildren: () => import('./sections/purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule) },
    { path: 'reports', loadChildren: () => import('./sections/reports/reports.module').then(m => m.ReportsModule) },
    { path: 'admin', loadChildren: () => import('./sections/admin/admin.module').then(m => m.AdminModule) },
    { path: 'control-panel', loadChildren: () => import('./sections/admin/admin.module').then(m => m.AdminModule) },
    { path: 'vendors', loadChildren: () => import('./sections/admin/admin.module').then(m => m.AdminModule) },
    { path: 'welcome', loadChildren: () => import('./sections/welcome/welcome.module').then(m => m.WelcomeModule) },
    { path: 'auth', loadChildren: () => import('./sections/auth/auth.module').then(m => m.AuthModule) },
    { path: 'logs', loadChildren: () => import('./sections/logs/logs.module').then(m => m.LogsModule) },
    { path: 'map', loadChildren: () => import('./sections/map/map.module').then(m => m.MapModule) },
    // { path: '**', redirectTo: 'converters' },
    // { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            // { preloadingStrategy: PreloadAllModules }
            // { enableTracing: true } // <-- debugging purposes only
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
