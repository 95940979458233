<div class="app-wrapper">
    <p-toast position="bottom-right"></p-toast>
    <ls-header [hideNavigation]="po != null || vendorIdForLogs != null"></ls-header>
    <div class="main-content-outer" [class.no-padding]="useNoPadding">
        <div *ngIf="po != null" class="po"><ls-purchase-order (hide)="po = null"></ls-purchase-order></div>
        <div *ngIf="vendorIdForLogs != null" class="logs"><ls-log-list [vendorId]="vendorIdForLogs" (hide)="vendorIdForLogs = null"></ls-log-list></div>
        <div [hidden]="po != null || vendorIdForLogs != null" class="main-content-inner">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<p-confirmDialog></p-confirmDialog>
<p-confirmDialog key="okOnly" [rejectVisible]="false" acceptLabel="OK" [closable]="false" [closeOnEscape]="false"></p-confirmDialog>
<p-confirmPopup key="popup"></p-confirmPopup>
