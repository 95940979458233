import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';

import { CaptureSignatureComponent } from '../../capture-signature/capture-signature.component';
import { Contact } from '../../../../services/vendor/contact.model';
import { CurrencyPipe } from '@angular/common';
import { PurchaseOrderManagerService } from '../../../../services/purchase-order/purchase-order-manager.service';
import { PurchaseOrderPDFComponent } from '../../purchase-order-pdf/purchase-order-pdf.component';
import { PurchaseOrderPaymentType } from '../../../../services/purchase-order/purchase-order.model';
import { ThemeManager } from '../../../../managers/theme.manager';

@Component({
    selector: 'ls-california-compliance-page',
    templateUrl: './california-compliance-page.component.html',
    styleUrls: [ './california-compliance-page.component.less' ]
})
export class CaliforniaCompliancePageComponent implements OnInit
{
    @ViewChild('initials1', { static: false }) initials1: CaptureSignatureComponent;
    @ViewChild('initials2', { static: false }) initials2: CaptureSignatureComponent;
    @ViewChild('initials3', { static: false }) initials3: CaptureSignatureComponent;
    @ViewChild('initials4', { static: false }) initials4: CaptureSignatureComponent;
    @ViewChild('initials5', { static: false }) initials5: CaptureSignatureComponent;
    @ViewChild('initials6', { static: false }) initials6: CaptureSignatureComponent;
    @ViewChild('initials7', { static: false }) initials7: CaptureSignatureComponent;
    @ViewChild('initials8', { static: false }) initials8: CaptureSignatureComponent;
    @ViewChild('initials9', { static: false }) initials9: CaptureSignatureComponent;
    @ViewChild('sellerSignature', { static: false }) sellerSignature: CaptureSignatureComponent;

    @Output() signatureCompleted = new EventEmitter<boolean>();

    currentPage = 1;
    placeOfSaleOption = 1;
    sellerContact: Contact;
    selectedPaymentTypes: PurchaseOrderPaymentType[] = [];
    vehicleLicenseNum = '';
    vehicleLicenseState = '';
    idNum = '';
    idState = '';
    name = '';

    headerText = 'Purchase Agreement';

    // General Info Page Text
    introRegularText1 = 'IN CONSIDERATION OF THE COVENANTS and agreements contained in this Purchase Agreement, the';
    introRegularText2 = 'parties to this Agreement agree as follows:';
    partiesText1 = ' (the "Seller"), of';
    partiesText2 = 'does hereby sell, assign and transfer to';
    partiesText3 = 'Legend Smelting And Recycling, LLC (the "Buyer"), ';
    partiesText4 = 'the following property...';
    paymentTypeHeaderText = 'Payment Type:';
    sellerInfoHeaderText = 'Seller information:';
    vehicleLicenseText = 'Vehicle License # __________________________________________';
    idText = 'ID or DL # _______________________________________________';
    stateText = 'State _______________';
    signaturePrelude1 = 'I, the undersigned, affirm under penalty of law that the property that is subject to this transaction is not';
    signaturePrelude2 = 'to the best of my knowledge stolen property. I certify that I am the legal owner of all merchandise sold.';
    signaturePrelude3 = 'The undersigned also affirms that they are authorized to receive payment on behalf of the above listed commercial enterprise.';
    signatureText = 'Signature ______________________________________________________';
    printText = 'Print _________________________________________________________';
    dateText = 'Date ___________________________';

    // Record of Junk Sale text
    stateOfCAText = 'STATE OF CALIFORNIA';
    bciaText = 'BCIA 127';
    origText = '(orig. 11/08)';
    miscHeaderText = 'RECORD OF JUNK SALE OR PURCHASE';
    deptOfJusticeText = 'DEPARTMENT OF JUSTICE';
    headerPageNumberText = 'PAGE 1 of 1';

    streetAddressLabel = 'Street Address';
    cityLabel = 'City';
    stateLabel = 'State';
    zipLabel = 'ZIP Code';
    lastNameLabel = 'Last Name';
    firstNameLabel = 'First Name';
    middleInitialLabel = 'Middle Initial';
    issuingStateLabel = 'Issuing State';
    caIdLabel = 'California Identification Card Number';
    vehicleLicensePlateLabel = 'Vehicle License Plate Number';
    itemTypeLabel = 'Item Type';
    itemQuantityLabel = 'Quantity';
    itemIdentificationNumberLabel = 'Identification Number (if visible)';
    nameLabel = 'Name';

    miscLine1Text = 'Pursuant to Business and Professions Code section 21606(a)(1-5), every junk dealer and recycler shall record all sales and purchases.';
    dateOfSaleLabel = 'Date of Sale or Purchase ___________________';
    dateOfSale: Date;

    placeOfSaleLabel = 'Place of Sale or Purchase';
    placeOfSaleStreetAddress = '';
    placeOfSaleCity = '';
    placeOfSaleState = '';
    placeOfSaleZip = '';

    sellerSectionLabel = 'Person and Motor Vehicle Used to Transport Junk to Place of Sale or Purchase';
    sellerLastName = '';
    sellerFirstName = '';
    sellerMiddleInitial = '';
    sellerDriversLicenseLabel = 'Driver\'s License Number';
    sellerDriversLicenseNumber = '';
    sellerDriversLicenseIssuingState = '';
    sellerCaIdNumber = '';
    sellerVehicleLicensePlateNumber = '';
    sellerVehicleLicensePlateIssuingState = '';

    buyerSectionLabel = 'Person to Whom Junk was Sold and Motor Vehicle Used to Transport Junk from Place of Sale or Purchase';
    buyerLicensePlateNumber = '';
    buyerLicensePlateIssuingState = '';

    descriptionSectionLabel = 'Description of Junk Sold or Purchased (Use additional form(s) if more than three item types)';
    items = [ {}, {}, {} ];

    sellerStatementSectionLabel = 'Junk Seller Statement (Complete either line A or line B)';
    sellerLineAText = ', own the junk items described above.';
    sellerLineBText = 'The junk described above was obtained from ';

    thumbprintText1 = `The buyer shall obtain the seller's thumbprint on the reverse side of this form. The buyer shall submit a copy of this `;
    thumbprintText2 = 'record, without thumbprint image, to the jurisdictional law enforcement agency. The buyer shall retain this record for ';
    thumbprintText3 = 'two years from the date of sale or purchase. ';

    columns = [
        { title: 'PO #', widthPercent: 35, align: 'center' },
        { title: 'Quantity', widthPercent: 15, align: 'center' },
        { title: 'Description', widthPercent: 15, align: 'center' },
        { title: 'Amount', widthPercent: 15, align: 'center' },
    ];

    paymentTypes = [
        { title: 'Cash', value: PurchaseOrderPaymentType.Cash },
        { title: 'Check', value: PurchaseOrderPaymentType.Check },
        { title: 'Wire Transfer', value: PurchaseOrderPaymentType.WireTransfer }
    ];

    placeOfSaleOptions = [
        { label: `Seller's Location`, value: 1 },
        { label: `Buyer's Location`, value: 2 },
        { label: 'Other', value: 3 },
    ];

    ThemeManager = ThemeManager;

    constructor(public poManager: PurchaseOrderManagerService, private currencyPipe: CurrencyPipe) { }

    ngOnInit(): void
    {
        if (this.poManager.current == null) return;
    }

    generateSignaturePage(parent: PurchaseOrderPDFComponent): void
    {
        this.generateGeneralInfoPdfPage(parent);
    }

    generateExtraPages(parent: PurchaseOrderPDFComponent): boolean
    {
        const isCashPayment = this.isCashPayment;
        if (isCashPayment)
        {
            this.generateRecordOfJunkSalePdfPage(parent, true, 0);
            for (let i = 2; i < parent.po.lineItemsMiscellaneous.length; i += 3)
            {
                this.generateRecordOfJunkSalePdfPage(parent, false, i);
            }
        }

        this.clearFields();
        return isCashPayment;
    }

    placeOfSaleOptionChanged(): void
    {
        if (this.placeOfSaleOption === 1)
        {
            // Seller's Location
            return;
        }

        if (this.placeOfSaleOption === 2)
        {
            return;
        }
    }

    private generateGeneralInfoPdfPage(parent: PurchaseOrderPDFComponent): void
    {
        const vendor = parent.po.vendor;

        // Add Title
        parent.outputComplianceHeader(this.headerText);

        // ---------- SECTION 1 START ----------
        parent.pdf.setFontSize(parent.pdfDimensions.general.regularFontSize);
        parent.pdf.setFont('helvetica', null, 'normal');
        parent.outputTextLine(this.introRegularText1);
        parent.outputTextLine(this.introRegularText2);
        // ---------- END SECTION 1 ----------


        // ---------- SECTION 2 START ----------
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(`${vendor.name} ${this.partiesText1} ${vendor.fullAddress},`);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(this.partiesText2);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(`${this.partiesText3} ${parent.po.location.fullAddress}`);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(this.partiesText4);
        // ---------- END SECTION 2 ----------

        // ---------- SECTION 3 START ----------
        // Use "table headers" to create the pseudo-table
        parent.createTableHeader(this.columns, true, false);
        const columnOutput = cloneDeep(this.columns);
        columnOutput[0].title = parent.po.number;
        columnOutput[1].title = 'Converters';
        columnOutput[2].title = parent.po.totalNumConverters.toString();
        columnOutput[3].title = this.currencyPipe.transform(parent.po.totalConvertersPrice, 'USD');
        parent.createTableHeader(columnOutput, true, false);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        // ---------- END SECTION 3 ----------


        // tslint:disable:max-line-length

        // ---------- SECTION 4 START ----------
        // tslint:disable:no-bitwise
        parent.outputTextLine(this.paymentTypeHeaderText);
        parent.result.bottomOfOutput += parent.lineHeight;
        let margin = 10;
        for (const paymentType of this.paymentTypes)
        {
            parent.outputCheckbox(margin, this.selectedPaymentTypes.some(pt => pt === paymentType.value));
            margin += 15;
            parent.outputText(paymentType.title, margin);
            margin += parent.textWidth(paymentType.title) + 40;
        }
        // tslint:enable:no-bitwise
        // ---------- END SECTION 4 ----------

        // ---------- SECTION 5 START ----------
        parent.result.bottomOfOutput += parent.lineHeight * 3;
        parent.outputTextLine(this.sellerInfoHeaderText);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`${this.vehicleLicenseText}  ${this.stateText}`);
        parent.outputText(this.vehicleLicenseNum, parent.textWidth(this.removeUnderscores(this.vehicleLicenseText) + 5));
        parent.outputTextLine(this.vehicleLicenseState, parent.textWidth(`${this.vehicleLicenseText}  `) +
            parent.textWidth(this.removeUnderscores(this.stateText) + 5));

        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`${this.idText}  ${this.stateText}`);
        parent.outputText(this.idNum, parent.textWidth(this.removeUnderscores(this.idText) + 5));
        parent.outputTextLine(this.idState, parent.textWidth(`${this.idText}  `) +
            parent.textWidth(this.removeUnderscores(this.stateText) + 5));
        // ---------- END SECTION 5 ----------

        // ---------- SECTION 6 START ----------
        parent.result.bottomOfOutput += parent.lineHeight * 4;
        parent.outputTextLine(this.signaturePrelude1);
        parent.outputTextLine(this.signaturePrelude2);
        parent.result.bottomOfOutput += parent.lineHeight * 1.5;
        parent.pdf.setFontSize(9);
        parent.outputTextLine(this.signaturePrelude3);
        parent.pdf.setFontSize(parent.pdfDimensions.general.regularFontSize);

        parent.result.bottomOfOutput += parent.lineHeight * 1.5;
        const imageHeight = parent.lineHeight * 2;
        const widthToHeightRatio = this.sellerSignature.widthToHeightRatio;
        parent.outputImage(this.sellerSignature.signature, 'PNG', parent.leftMargin + parent.textWidth(this.removeUnderscores(this.signatureText)), imageHeight * widthToHeightRatio, imageHeight);
        parent.outputTextLine(this.signatureText);

        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`${this.printText}  ${this.dateText}`);
        parent.outputText(this.name, parent.textWidth(this.removeUnderscores(this.printText) + 5));
        parent.outputTextLine(moment(this.dateOfSale).format('MM-DD-yyyy'), parent.textWidth(`${this.printText}  `) +
            parent.textWidth(this.removeUnderscores(this.dateText) + 5));
        // ---------- END SECTION 6 ----------
        // tslint:enable:max-line-length
    }

    private generateRecordOfJunkSalePdfPage(parent: PurchaseOrderPDFComponent, outputConverters: boolean, startingMiscIndex: number): void
    {
        const general = parent.pdfDimensions.general;
        if (startingMiscIndex > 0) parent.createNewPdfPage(false);

        // Add Header
        // Header-left and header-right
        const centerForText = parent.center - parent.leftMargin;
        parent.pdf.addImage('assets/images/ca_doj_logo.png', 'PNG', parent.leftMargin, parent.result.bottomOfOutput, 30, 30);
        parent.pdf.setFontSize(7);
        parent.outputTextLines([ this.stateOfCAText, this.bciaText, this.origText ], 32);
        parent.result.bottomOfOutput -= parent.lineHeight * 3;
        parent.outputTextLines([ this.deptOfJusticeText, this.headerPageNumberText ],
            parent.rightMargin - parent.textWidth(this.deptOfJusticeText) - 15);
        parent.result.bottomOfOutput -= parent.lineHeight;

        // Header-center
        parent.pdf.setFontSize(14);
        parent.outputTextLine(this.miscHeaderText, centerForText - parent.textWidth(this.miscHeaderText) / 2);
        parent.result.bottomOfOutput += parent.lineHeight * 0.8;

        // Intro line
        parent.pdf.setFontSize(8);
        this.outputDividerLine(parent);
        parent.outputTextLine(this.miscLine1Text);

        // Date of Sale
        this.outputDividerLine(parent);
        parent.result.bottomOfOutput += parent.lineHeight * 0.5;
        parent.pdf.setFontSize(general.regularFontSize);
        parent.pdf.setFont('helvetica', 'bold');
        parent.outputText(this.dateOfSaleLabel);
        parent.pdf.setFont('helvetica', null, 'normal');
        parent.outputText(moment(this.dateOfSale).format('MM-DD-yyyy'),
            parent.textWidth(this.removeUnderscores(this.dateOfSaleLabel)) + 20);
        parent.result.bottomOfOutput += parent.lineHeight;
        this.outputDividerLine(parent);

        // Place of Sale line
        parent.pdf.setFont('helvetica', 'bold');
        parent.outputTextLine(this.placeOfSaleLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 0.5;

        parent.pdf.setFont('helvetica', null, 'normal');
        this.outputTextOnLabeledLine(parent, 0, 48, this.placeOfSaleStreetAddress, this.streetAddressLabel);
        this.outputTextOnLabeledLine(parent, 50, 28, this.placeOfSaleCity, this.cityLabel);
        this.outputTextOnLabeledLine(parent, 80, 7, this.placeOfSaleState, this.stateLabel);
        this.outputTextOnLabeledLine(parent, 89, 11, this.placeOfSaleZip, this.zipLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 1.5;

        // Seller Info
        this.outputDividerLine(parent);
        parent.result.bottomOfOutput += parent.lineHeight * 0.8;
        parent.pdf.setFont('helvetica', 'bold');
        parent.outputTextLine(this.sellerSectionLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 0.5;

        parent.pdf.setFont('helvetica', null, 'normal');
        this.outputTextOnLabeledLine(parent, 0, 48, this.sellerLastName, this.lastNameLabel);
        this.outputTextOnLabeledLine(parent, 50, 23, this.sellerFirstName, this.firstNameLabel);
        this.outputTextOnLabeledLine(parent, 75, 25, this.sellerMiddleInitial, this.middleInitialLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 2;

        this.outputTextOnLabeledLine(parent, 0, 23, this.sellerDriversLicenseNumber, this.sellerDriversLicenseLabel);
        this.outputTextOnLabeledLine(parent, 25, 20, this.sellerDriversLicenseIssuingState, this.issuingStateLabel);
        parent.outputText('OR', general.bodyWidth * 47 / 100);
        this.outputTextOnLabeledLine(parent, 50, 50, this.sellerCaIdNumber, this.caIdLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 2;

        this.outputTextOnLabeledLine(parent, 0, 23, this.sellerVehicleLicensePlateNumber, this.vehicleLicensePlateLabel);
        this.outputTextOnLabeledLine(parent, 25, 20, this.sellerVehicleLicensePlateIssuingState, this.issuingStateLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 1.5;

        // Buyer info
        this.outputDividerLine(parent);
        parent.result.bottomOfOutput += parent.lineHeight * 0.8;
        parent.pdf.setFont('helvetica', 'bold');
        parent.outputTextLine(this.buyerSectionLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 0.5;

        parent.pdf.setFont('helvetica', null, 'normal');
        this.outputTextOnLabeledLine(parent, 0, 48, '', this.lastNameLabel);
        this.outputTextOnLabeledLine(parent, 50, 23, '', this.firstNameLabel);
        this.outputTextOnLabeledLine(parent, 75, 25, '', this.middleInitialLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 2;

        this.outputTextOnLabeledLine(parent, 0, 48, '', this.streetAddressLabel);
        this.outputTextOnLabeledLine(parent, 50, 28, '', this.cityLabel);
        this.outputTextOnLabeledLine(parent, 80, 7, '', this.stateLabel);
        this.outputTextOnLabeledLine(parent, 89, 11, '', this.zipLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 2;

        this.outputTextOnLabeledLine(parent, 0, 23, '', this.vehicleLicensePlateLabel);
        this.outputTextOnLabeledLine(parent, 25, 20, '', this.issuingStateLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 1.5;

        // Description section
        this.outputDividerLine(parent);
        parent.result.bottomOfOutput += parent.lineHeight * 0.8;
        parent.pdf.setFont('helvetica', 'bold');
        parent.outputTextLine(this.descriptionSectionLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 0.5;

        parent.pdf.setFont('helvetica', null, 'normal');
        let itemLineCount = 0;
        if (outputConverters)
        {
            this.outputTextOnLabeledLine(parent, 0, 38, 'Catalytic Converters', this.itemTypeLabel);
            this.outputTextOnLabeledLine(parent, 40, 8, this.poManager.current.totalNumConverters.toString(), this.itemQuantityLabel);
            this.outputTextOnLabeledLine(parent, 50, 50, `Reference Purchase Order Number: ${this.poManager.current.number}`,
                this.itemIdentificationNumberLabel);
            parent.result.bottomOfOutput += parent.lineHeight * 2;
            itemLineCount++;
        }

        for (let i = 0; i < (outputConverters ? 2 : 3); i++)
        {
            const li = i + startingMiscIndex >= parent.po.numMiscellaneous ? null : parent.po.lineItemsMiscellaneous[i + startingMiscIndex];
            this.outputTextOnLabeledLine(parent, 0, 38, li == null ? '' : li.description, this.itemTypeLabel);
            this.outputTextOnLabeledLine(parent, 40, 8, li == null ? '' : li.quantity.toString(), this.itemQuantityLabel);
            this.outputTextOnLabeledLine(parent, 50, 50, '', this.itemIdentificationNumberLabel);
            parent.result.bottomOfOutput += parent.lineHeight * 2;
        }
        parent.result.bottomOfOutput -= parent.lineHeight * 0.5;

        // Seller statement
        this.outputDividerLine(parent);
        parent.pdf.setFont('helvetica', 'bold');
        parent.outputTextLine(this.sellerStatementSectionLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 1.5;
        parent.pdf.setFont('helvetica', null, 'normal');
        parent.outputText('A.  I, ', 20);
        let left = parent.textWidth('A.  I, ') + 20;
        this.outputTextOnLabeledLine(parent, left / general.bodyWidth * 100, 40, '', this.nameLabel);
        const imageHeight = parent.lineHeight * 2;
        const widthToHeightRatio = this.sellerSignature.widthToHeightRatio;
        const imageWidth = imageHeight * widthToHeightRatio;
        parent.outputImage(this.sellerSignature.signature, 'PNG', left + 30, imageWidth, imageHeight);
        left += 40 * general.bodyWidth / 100;
        parent.outputText(this.sellerLineAText, left);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`B.  ${this.sellerLineBText} `, 20);
        left = parent.textWidth(`B.  ${this.sellerLineBText} `) + 20;
        this.outputTextOnLabeledLine(parent, left / general.bodyWidth * 100, 40, 'N/A', this.nameLabel);
        parent.result.bottomOfOutput += parent.lineHeight * 1.5;

        // Signature
        // parent.pdf.setFontSize(general.regularFontSize);
        // this.outputDividerLine(parent);
        // parent.outputTextLines([this.sellerSignatureLabel1, this.sellerSignatureLabel2 ], 10);
        //
        // const imageHeight = parent.lineHeight * 2;
        // const widthToHeightRatio = this.sellerSignature.widthToHeightRatio;
        // const imageWidth = imageHeight * widthToHeightRatio;
        // parent.result.bottomOfOutput += parent.lineHeight * 2;
        // parent.outputImage(this.sellerSignature.signature, 'PNG', parent.center - imageWidth / 2, imageWidth, imageHeight);
        // parent.pdf.line(parent.center - imageWidth / 2, parent.result.bottomOfOutput, parent.rightMargin - imageWidth / 2,
        //     parent.result.bottomOfOutput);
        // parent.result.bottomOfOutput += parent.lineHeight;
        // parent.outputTextLine(`Seller or Seller's Representative's Signature`, parent.center - imageWidth / 2 - general.marginLeft);

        // Thumbprint line
        parent.pdf.setFontSize(10);
        parent.pdf.setFont('helvetica', 'bold');
        this.outputDividerLine(parent);
        parent.outputTextLines([this.thumbprintText1, this.thumbprintText2, this.thumbprintText3 ]);
    }

    get partiesTextHTML(): string
    {
        const vendor = this.poManager.current.vendor;
        return `<div class="party-line">${vendor.name} ${this.partiesText1} ${vendor.fullAddress},</div>` +
            `<div>${this.partiesText2}</div>` + `<div>${this.partiesText3} ${this.poManager.current.location.fullAddress}</div>` + `<div>${this.partiesText4}</div>`;
    }

    get signaturePrelude(): string
    {
        return `${this.signaturePrelude1} ${this.signaturePrelude2}`;
    }

    get signaturePreludeWarning(): string
    {
        return `${this.signaturePrelude3}`;
    }

    removeUnderscores(text: string): string
    {
        return text.replace(/_/g, '');
    }

    clearSignatures(): void
    {
        this.sellerSignature.clear();
    }

    get isCashPayment(): boolean
    {
        return this.selectedPaymentTypes.some(pt => pt === PurchaseOrderPaymentType.Cash);
    }

    onCancel(): void
    {
        this.clearFields();
        this.signatureCompleted.emit(false);
    }

    private clearFields(): void {
        this.currentPage = 1;
        this.selectedPaymentTypes = [];
        this.vehicleLicenseNum = '';
        this.vehicleLicenseState = '';
        this.idNum = '';
        this.idState = '';
        this.name = '';
        this.dateOfSale = undefined;
        this.placeOfSaleOption = 1;
        this.placeOfSaleStreetAddress = '';
        this.placeOfSaleCity = '';
        this.placeOfSaleState = '';
        this.placeOfSaleZip = '';
        this.sellerContact = undefined;
        this.sellerLastName = '';
        this.sellerFirstName = '';
        this.sellerMiddleInitial = '';
        this.sellerDriversLicenseNumber = '';
        this.sellerDriversLicenseIssuingState = '';
        this.sellerCaIdNumber = '';
        this.sellerVehicleLicensePlateNumber = '';
        this.sellerVehicleLicensePlateIssuingState = '';
        this.clearSignatures();
    }

    onConfirmGeneralPage(): void
    {
        this.poManager.current.paymentType =
            // tslint:disable-next-line:no-bitwise
            (this.selectedPaymentTypes.includes(PurchaseOrderPaymentType.Cash) ? PurchaseOrderPaymentType.Cash : 0) |
            (this.selectedPaymentTypes.includes(PurchaseOrderPaymentType.Check) ? PurchaseOrderPaymentType.Check : 0) |
            (this.selectedPaymentTypes.includes(PurchaseOrderPaymentType.WireTransfer) ? PurchaseOrderPaymentType.WireTransfer : 0);
        if (this.isCashPayment)
        {
            this.currentPage = 2;
            this.onPlaceOfSaleOptionChanged();
            return;
        }

        this.signatureCompleted.emit(true);
    }

    onConfirmRecordOfSalePage(): void
    {
        this.currentPage = 1;
        this.idNum = this.sellerDriversLicenseNumber.length > 0 ? this.sellerDriversLicenseNumber : this.sellerCaIdNumber;
        this.idState = this.sellerDriversLicenseIssuingState;
        this.vehicleLicenseNum = this.sellerVehicleLicensePlateNumber;
        this.vehicleLicenseState = this.sellerVehicleLicensePlateIssuingState;
        this.signatureCompleted.emit(true);
    }

    onPlaceOfSaleOptionChanged(): void
    {
        if (this.placeOfSaleOption === 1)
        {
            this.placeOfSaleStreetAddress = this.poManager.current.vendor.address;
            this.placeOfSaleCity = this.poManager.current.vendor.city;
            this.placeOfSaleState = this.poManager.current.vendor.state;
            this.placeOfSaleZip = this.poManager.current.vendor.zip;
            return;
        }
        if (this.placeOfSaleOption === 2)
        {
            this.placeOfSaleStreetAddress = this.poManager.current.location.address;
            this.placeOfSaleCity = this.poManager.current.location.city;
            this.placeOfSaleState = this.poManager.current.location.state;
            this.placeOfSaleZip = this.poManager.current.location.zip;
            return;
        }

        this.placeOfSaleStreetAddress = '';
        this.placeOfSaleCity = '';
        this.placeOfSaleState = '';
        this.placeOfSaleZip = '';
    }

    onSelectedContactChanged(): void
    {
        this.sellerLastName = this.sellerContact.lastName ?? '';
        this.sellerFirstName = this.sellerContact.firstName ?? '';
        this.sellerDriversLicenseNumber = this.sellerContact.driversLicenseNumber ?? '';
        this.sellerDriversLicenseIssuingState = this.sellerContact.driversLicenseState ?? '';
    }

    private outputDividerLine(parent: PurchaseOrderPDFComponent): void
    {
        parent.pdf.setDrawColor(0);
        parent.pdf.setFillColor('black');
        parent.pdf.rect(parent.pdfDimensions.general.marginLeft, parent.result.bottomOfOutput, parent.pdfDimensions.general.bodyWidth, 1, 'DF');
        parent.result.bottomOfOutput += parent.lineHeight * 1.1;
    }

    private outputTextOnLabeledLine(parent: PurchaseOrderPDFComponent, leftPercent: number, widthPercent: number,
                                    text: string, label: string): void
    {
        const general = parent.pdfDimensions.general;
        const bottom = parent.result.bottomOfOutput;

        const left = general.bodyWidth * leftPercent / 100;
        const width = general.bodyWidth * widthPercent / 100;

        parent.outputText(text, left + 3);
        parent.result.bottomOfOutput += parent.lineHeight * 0.3;
        const y = parent.result.bottomOfOutput;
        parent.pdf.line(parent.leftMargin + left, y, parent.leftMargin + left + width, y);
        parent.result.bottomOfOutput += parent.lineHeight * 0.4;

        const fontSize = parent.pdf.getFontSize();
        parent.pdf.setFontSize(7);
        parent.outputTextLine(label, left);
        parent.pdf.setFontSize(fontSize);

        // Restore bottom
        parent.result.bottomOfOutput = bottom;
    }

    sellerHasInfo(propertyName: string): boolean
    {
        return this.sellerContact != null && this.sellerContact[propertyName] != null && this.sellerContact[propertyName].length > 0;
    }
}
