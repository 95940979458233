export enum PricingType
{
    ByPiece = 0,
    ByPound = 1
}

export class MiscellaneousPart
{
    public id: number;
    partNumber: string;
    description: string;
    pricePerItem: number;
    pricingType = PricingType.ByPiece;

    constructor(json: any)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}
