export class CalculatePricesResult
{
    public platinumTroyOz = 0;
    public palladiumTroyOz = 0;
    public rhodiumTroyOz = 0;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}
