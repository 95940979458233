import { Injectable, Injector } from '@angular/core';
import { AuthManagerService } from '../../../services/auth/auth-manager.service';

@Injectable({
    providedIn: 'root'
})
export class AdminManagerService
{
    private _lastTab: string;

    constructor(private injector: Injector) { }

    get lastTab(): string
    {
        if (this._lastTab == null)
        {
            const authManagerService = this.injector.get(AuthManagerService);
            this._lastTab = authManagerService.currentUser.isVendorAdmin ? 'hedges' : 'vendors-users';
        }
        return this._lastTab;
    }

    set lastTab(value: string) { this._lastTab = value; }

    resetLastTab(): void { this._lastTab = null; }
}
