import { Permission } from './permission.model';

export class Role
{
    role: string;
    permissions: Permission[] = [];

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);

            if (json.permissions != null) { this.permissions = json.permissions.map(item => new Permission(item)); }
        }
    }

    get friendlyString(): string
    {
        switch (this.role.toUpperCase())
        {
            case 'GLOBALADMIN': return 'Global Admin';
            case 'LOCATIONADMIN': return 'Location Admin';
            case 'LOCATIONACCOUNTANT': return 'Accounting';
            case 'VENDORADMIN': return 'Vendor Admin';
            case 'LIMITEDADMIN': return 'Limited Admin';
            default: return this.role;
        }
    }

    get iconHTML(): string
    {
        return Role.iconHTML(this.role);
    }

    static iconHTML(role: string): string
    {
        switch (role.toUpperCase())
        {
            case 'GLOBALADMIN': return '<i class="fas fa-user-shield"></i>';
            case 'LOCATIONADMIN': return '<i class="fa fa-user-tie"></i>';
            case 'LOCATIONACCOUNTANT': return '<i class="fa fa-money-bill"></i>';
            case 'VENDORADMIN': return '<i class="fa fa-user-plus"></i>';
            case 'LIMITEDADMIN': return '<i class="fa fa-user-minus"></i>';
            case 'BUYER': return '<i class="fa fa-user"></i>';
            default: return '';
        }
    }
}
