import { Subject } from 'rxjs';
import { PurchaseOrder } from './purchase-order/purchase-order.model';
import { WidgetType } from './widget/widget.type';

export class BroadcastService
{
    // POs
    displayPurchaseOrder = new Subject<PurchaseOrder>();

    // Widgets
    removeWidget = new Subject<{ widgetId: number, widgetType: WidgetType }>();
    // showDataSettingsDialog = new Subject<WidgetData>();
    // widgetDataSettingsChange = new Subject<WidgetDataSettings>();

    // Logs
    displayLogsForVendor = new Subject<number>();
    deletedLog = new Subject<number>();

    // Other
    currencyPricesLoaded = new Subject();
    currencyChanged = new Subject<string>();
}
