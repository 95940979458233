import { Vendor } from '../vendor/vendor.model';
import { Location } from '../location/location.model';
import { PurchaseOrderLineItem, PurchaseOrderLineItemMiscellaneous } from './purchase-order-line-item.model';
import { Utils } from '../utils.service';
import { PurchaseOrderTransactionPartType } from './purchase-order-transaction';

export enum PurchaseOrderPaymentType
{
    None = 0,
    Cash = 0x01,
    Check = 0x02,
    WireTransfer = 0x04
}

export class PurchaseOrder
{
    public id: number;
    public number: string;
    public vendorId: number;
    public vendorName: string;
    public vendor: Vendor;
    public buyerName: string;
    public buyerFirstName: string;
    public buyerLastName: string;
    public imageUrls: string[];
    public locationId: number;
    public location: Location;
    public lineItemsAllConverters: PurchaseOrderLineItem[] = [];
    public lineItemsConverter: PurchaseOrderLineItem[] = [];
    public lineItemsNoNumber: PurchaseOrderLineItem[] = [];
    public lineItemsCustom: PurchaseOrderLineItem[] = [];
    public lineItemsCategory: PurchaseOrderLineItem[] = [];
    public lineItemsMiscellaneous: PurchaseOrderLineItemMiscellaneous[] = [];
    public purchaseDate: Date; // This is in LOCATION timezone
    public exportedDate: Date; // This is in LOCATION timezone
    public signaturePageUrl: any;
    public extraPagesUrl: any;
    public totalPrice: number;
    public numConverters: number;
    public numCustom: number;
    public numNoNumber: number;
    public numCategory: number;
    public numMiscellaneous: number;
    public isLocked: boolean;
    public hasBeenLocked: boolean;
    public paymentType: number;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.vendor != null)
            {
                this.vendor = new Vendor(json.vendor);
                this.vendorName = this.vendor.name;
            }
            if (json.location) this.location = new Location(json.location);
            if (json.purchaseDate != null) this.purchaseDate = Utils.convertApiDateStringToDate(json.purchaseDate);
            if (json.exportedDate != null) this.exportedDate = Utils.convertApiDateStringToDate(json.exportedDate);
            if (json.lineItemsConverter) this.lineItemsConverter =
                json.lineItemsConverter.map(item => new PurchaseOrderLineItem(item, PurchaseOrderTransactionPartType.Converter))
                    .sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
            if (json.lineItemsCustom) this.lineItemsCustom =
                json.lineItemsCustom.map(item => new PurchaseOrderLineItem(item, PurchaseOrderTransactionPartType.Custom))
                    .sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
            if (json.lineItemsNoNumber) this.lineItemsNoNumber =
                json.lineItemsNoNumber.map(item => new PurchaseOrderLineItem(item, PurchaseOrderTransactionPartType.NoNumber))
                    .sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
            if (json.lineItemsCategory) this.lineItemsCategory =
                json.lineItemsCategory.map(item => new PurchaseOrderLineItem(item, PurchaseOrderTransactionPartType.Category))
                    .sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
            if (json.lineItemsMiscellaneous)
                this.lineItemsMiscellaneous = json.lineItemsMiscellaneous.map(item => new PurchaseOrderLineItemMiscellaneous(item))
                    .sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
            this.lineItemsAllConverters = this.lineItemsConverter
                .concat(this.lineItemsCustom, this.lineItemsNoNumber, this.lineItemsCategory)
                .sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
            if (this.buyerName == null) this.buyerName = `${this.buyerFirstName} ${this.buyerLastName}`;
            if (this.imageUrls == null) this.imageUrls = [];
        }
    }

    get totalNumConverters(): number { return this.numConverters + this.numCustom + this.numNoNumber + this.numCategory; }
    get totalConvertersPrice(): number {
        return Utils.sum(this.lineItemsConverter, 'totalPrice') +
            Utils.sum(this.lineItemsCustom, 'totalPrice') +
            Utils.sum(this.lineItemsNoNumber, 'totalPrice') +
            Utils.sum(this.lineItemsCategory, 'totalPrice');
    }

    updateQuantitiesAndPrices(): void
    {
        this.numConverters = this.lineItemsConverter.reduce((sum, lineItem) => sum + lineItem.quantity, 0);
        this.numNoNumber = this.lineItemsNoNumber.reduce((sum, lineItem) => sum + lineItem.quantity, 0);
        this.numCategory = this.lineItemsCategory.reduce((sum, lineItem) => sum + lineItem.quantity, 0);
        this.numCustom = this.lineItemsCustom.reduce((sum, lineItem) => sum + lineItem.quantity, 0);
        this.numMiscellaneous = this.lineItemsMiscellaneous.reduce((sum, lineItem) => sum + lineItem.quantity, 0);
        this.lineItemsAllConverters = this.lineItemsConverter.concat(this.lineItemsCustom, this.lineItemsNoNumber, this.lineItemsCategory)
            .sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
        this.updateTotalPrice();
    }

    private updateTotalPrice(): number
    {
        this.totalPrice = 0;
        this.lineItemsConverter.forEach(item => this.totalPrice += item.totalPrice);
        this.lineItemsCustom.forEach(item => this.totalPrice += item.totalPrice);
        this.lineItemsNoNumber.forEach(item => this.totalPrice += item.totalPrice);
        this.lineItemsCategory.forEach(item => this.totalPrice += item.totalPrice);
        this.lineItemsMiscellaneous.forEach(item => this.totalPrice += item.totalPrice);

        return this.totalPrice;
    }
}
