import { Utils } from '../utils.service';
import * as moment from 'moment';

export enum HedgeQuantityType
{
    Units,
    Pounds
}

export enum HedgeMaterialType
{
    CeramicAutocatalyst,
    SiliconCarbide,
    FoilAutocatalyst,
    Conventional
}

export class Hedge
{
    public completed = false;
    public contractDate: Date;
    public contractNumber: string;
    public finalSettlementUrl: string;
    public id: number;
    public lastUpdated: Date;
    public legendSignatureUrl: string;
    public materialType: HedgeMaterialType;
    public notes: string;
    public palladiumPrice = 0;
    public palladiumQuantity = 0;
    public platinumPrice = 0;
    public platinumQuantity = 0;
    public quantity: number;
    public quantityReceived: number;
    public quantityType: HedgeQuantityType;
    public received = false;
    public receivedDate: Date;
    public receivingLogUrl: string;
    public rhodiumPrice = 0;
    public rhodiumQuantity = 0;
    public sampled = false;
    public signaturePageUrl: string;
    public signedDate: Date;
    public updatedBy: string;
    public vendorId: number;
    public vendorName: string;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.contractDate != null && !(this.contractDate instanceof Date))
                this.contractDate = Utils.convertApiDateStringToDate(json.contractDate.toString());
            if (json.lastUpdated != null) this.lastUpdated = Utils.convertApiDateStringToDate(json.lastUpdated);
            if (json.receivedDate != null && !(this.receivedDate instanceof Date))
                this.receivedDate = Utils.convertApiDateStringToDate(json.receivedDate.toString());
        }
    }

    get isContractOverdue(): boolean
    {
        const m = moment(this.contractDate);
        const dueDate = moment().startOf('day').subtract(30, 'day');
        return m < dueDate;
    }

    get materialTypeText(): string
    {
        switch (this.materialType)
        {
            case HedgeMaterialType.SiliconCarbide: return 'Silicon Carbide';
            case HedgeMaterialType.CeramicAutocatalyst: return 'Ceramic Autocatalyst';
            case HedgeMaterialType.FoilAutocatalyst: return 'Foil Autocatalyst';
            case HedgeMaterialType.Conventional: return 'Conventional';
        }
    }

    get pricesValid(): boolean { return this.platinumPrice !== 0 && this.palladiumPrice !== 0 && this.rhodiumPrice !== 0; }

    get statusString(): string
    {
        if (this.signaturePageUrl == null) return 'Signature Req.';
        if (this.completed) return 'Completed';
        if (this.sampled) return 'Sampled';
        if (this.received) return 'Received';
        return 'Open';
    }

}
