import { Utils } from '../utils.service';

export class ProfitTier
{
    public level: number;
    public min: number;
    public max: number;
    public percentProfit: number;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}

export class ProfitTierGroup
{
    public id: number;
    public useFlatPricing = false;
    public flatPercentProfit = 0.15;
    public profitTiers: ProfitTier[] = [];
    public removed = true;
    public lastUpdated: Date;
    public updatedBy: string;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.lastUpdated != null) this.lastUpdated = Utils.convertApiDateStringToDate(json.lastUpdated);
            if (json.profitTiers) this.profitTiers = json.profitTiers.map(info => new ProfitTier(info));
        }
    }
}
